import { FC, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import PersonForm from '../../../modules/manager-cabinet/components/persons/PersonForm';
import TooltipText from './TooltipText';

const PersonEdit: FC = () => {
  const { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    const personId = Number(id);

    if (!personId) {
      navigate('/cabinet/person', { replace: true });
    }
  }, [id]);

  return (
    <div className="container mx-auto p-3 pb-10">
      <Link
        to="/cabinet/person"
        className="font-medium text-blue-600 hover:underline"
      >
        Назад к списку
      </Link>
      <div className="flex gap-10 sm:flex-row flex-col ">
        <div className="sm:w-2/4 w-full mt-3">
          <PersonForm editId={id} />
        </div>
      </div>
      <TooltipText />
    </div>
  );
};

export default PersonEdit;
