import Moment from 'moment';

interface DateApproximate {
  date: Date | string;
  isApproximate: boolean;
}

export default function ApproximateDate({
  isApproximate,
  date,
}: DateApproximate) {
  return (
    <>
      {isApproximate ? (
        <div className="italic inline-block">{Moment(date).format('Y')} г.</div>
      ) : (
        Moment(date).format('DD.MM.Y')
      )}
    </>
  );
}
