import { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PersonPhotoList from '../../../modules/manager-cabinet/components/persons/PersonPhotoList';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import Spinner from '../../../modules/common/Spinner';
import { useNavigate } from 'react-router-dom';
import PersonService from '../../../api/PersonService';
import PersonPhotoService from '../../../api/PersonPhotoService';
import PersonPhotoForm from '../../../modules/manager-cabinet/components/persons/PersonPhotoForm';

const History: FC = () => {
  const { isLoading } = useTypedSelector((state) => state.persons);
  const { isAuth } = useTypedSelector((state) => state.auth);
  const [personPhotos, setPersonPhotos] = useState([] as any);
  const [person, setPerson] = useState({
    id: null,
    surname: '',
    givenNames: '',
  });
  let navigate = useNavigate();
  const { personId } = useParams();

  useEffect(() => {
    if (isAuth) {
      PersonService.getPerson(Number(personId)).then(({ data }) => {
        setPerson(data);
        loadPhotos()
      });
    } else {
      navigate('/login', { replace: true });
    }
  }, []);
  
  const loadPhotos = (page = 1) => {
    PersonPhotoService.getList(1, {
      personId: Number(personId),
    }).then(({ data }) => {
      setPersonPhotos(data);
    });
  };

  if (!person.id) {
    return <></>;
  }

  return (
    <div className="container mx-auto px-2 pb-10">
      <div className="flex flex-wrap justify-between items-center mx-auto flex-col sm:flex-row">
        <div>
          <Link to={`/cabinet/person/`} className="underline">
            Назад
          </Link>
          <h1 className="mb-4 text-xl font-extrabold tracking-tight leading-none text-gray-900 md:text-2xl lg:text-3xl">
            Фотографии: {person.surname} {person.givenNames}
          </h1>
        </div>
      </div>
      <div className='p-5 bg-slate-200 '>
        <PersonPhotoForm handleLoadPhotos={loadPhotos} personId={person.id} />
      </div>
      {
      isLoading ? 
        <Spinner /> : 
        <PersonPhotoList 
          photos={personPhotos} 
          personId={person.id} 
          handleLoadPhotos={loadPhotos} 
        />
      }
    </div>
  );
};

export default History;
