import { FC, useEffect, useState } from 'react';
import ManagerList from '../../modules/manager-cabinet/components/users/UserList';
import ManagerModal from '../../modules/manager-cabinet/components/users/UserModal';
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import Spinner from '../../modules/common/Spinner';
import { useNavigate } from 'react-router-dom';

const Manager: FC = () => {
  const [editId, setEditId] = useState(0);
  const { loadUsers, showUserModal } = useActions();
  const { isLoading } = useTypedSelector((state) => state.users);
  const { isAuth } = useTypedSelector((state) => state.auth);
  let navigate = useNavigate();

  useEffect(() => {
    if (!isAuth) {
      navigate('/login', { replace: true });
    } else {
      loadUsers();
    }
  }, []);

  const handleEdit = (id: number) => {
    showUserModal(true);
    setEditId(id);
  };
  const openModal = () => {
    setEditId(0);
    showUserModal(true);
  };

  return (
    <div className="container mx-auto px-2 pb-10">
      <div className="flex flex-wrap justify-between items-center mx-auto">
        <h1 className="mb-4 text-xl font-extrabold tracking-tight leading-none text-gray-900 md:text-2xl lg:text-3xl ">
          Список пользователей
        </h1>
        <button
          onClick={openModal}
          type="button"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 "
        >
          Добавить пользователя
        </button>
      </div>
      <ManagerModal editId={editId} />
      {isLoading ? <Spinner /> : <ManagerList handleEdit={handleEdit} />}
    </div>
  );
};

export default Manager;
