import { PersonsAction, PersonsActionEnum, PersonsState } from './types';

const initialState: PersonsState = {
  persons: {},
  isLoading: false,
  error: '',
  successCreate: false,
  showEditModal: false,
};

export default function personsReducer(
  state = initialState,
  action: PersonsAction,
): PersonsState {
  switch (action.type) {
    case PersonsActionEnum.SET_PERSONS:
      return { ...state, persons: action.payload };
    case PersonsActionEnum.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case PersonsActionEnum.SET_ERROR:
      return { ...state, error: action.payload };
    case PersonsActionEnum.SET_SUCCESS_CREATE:
      return { ...state, successCreate: action.payload };
    case PersonsActionEnum.SET_EDIT_MODAL:
      return { ...state, showEditModal: action.payload };
    default:
      return state;
  }
}
