import {
  PersonsActionEnum,
  SetEditModalAction,
  SetPersonsAction,
  SetIsLoadingAction,
  SetErrorAction,
  SetSuccessCreateAction,
} from './types';
import { AppDispatch } from '../../index';
import PersonService from '../../../api/PersonService';
import { AuthActionCreators } from '../auth/action-creators';

export const PersonsActionCreators = {
  showPersonModal: (show: boolean): SetEditModalAction => ({
    type: PersonsActionEnum.SET_EDIT_MODAL,
    payload: show,
  }),
  setPersons: (persons: any): SetPersonsAction => ({
    type: PersonsActionEnum.SET_PERSONS,
    payload: persons,
  }),
  setIsLoading: (payload: boolean): SetIsLoadingAction => ({
    type: PersonsActionEnum.SET_IS_LOADING,
    payload,
  }),
  setError: (payload: string): SetErrorAction => ({
    type: PersonsActionEnum.SET_ERROR,
    payload,
  }),
  setSuccessCreate: (payload: boolean): SetSuccessCreateAction => ({
    type: PersonsActionEnum.SET_SUCCESS_CREATE,
    payload,
  }),
  loadPersons:
    (page: number = 1, query: string = '') =>
    (dispatch: AppDispatch) => {
      dispatch(PersonsActionCreators.setIsLoading(true));
      return PersonService.getList(page, query)
        .then(({ data }) => {
          dispatch(PersonsActionCreators.setPersons(data));
        })
        .catch((err) => {
          console.log(err);
          AuthActionCreators.logout();
        })
        .finally(() => {
          dispatch(PersonsActionCreators.setIsLoading(false));
        });
    },
};
