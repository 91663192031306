import { FC } from 'react';

const TooltipText: FC = () => {
  return (
  <div className=' bg-slate-50 rounded-xl w-full text-sm p-4 mt-10'>
    <h1 className='text-gray-600 text-lg font-bold leading-5 mb-3'>Рекомендации по заполнению карточки профиля</h1>
    <ul className='list-disc pl-5'>
      <li>Все обязательные поля помечены звездочкой;</li>
      <li>Девичью фамилию можно указать в скобках: Васильева (Иванова);</li>
      <li>Чтобы корректно очистить поле у родителей, необходимо нажать кнопку "очистить";</li>
    </ul>
  </div>
  );
};

export default TooltipText;
