type AlertType = {
  type?: 'danger' | 'success' | 'warning' | 'info';
  icon?: '';
  text: string;
};

export default function Alert(props: AlertType) {
  return (
    <>
      <div
        className="p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg"
        role="alert"
      >
        {props.text}
      </div>
    </>
  );
}
