import React, { useState } from 'react';
import Alert from '../../../common/Alert';
import PersonPhotoService from '../../../../api/PersonPhotoService';

export default function PersonPhotoForm(props: any) {
  const [description, setDescription] = useState('');
  const [year, setYear] = useState('');
  const [isApproximateYear, setIsApproximateYear] = useState(false);
  const [photo, setPhoto] = useState({});

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleAdd = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setError('');
    setSuccess('');

    const formData = new FormData();

    if (photo instanceof File) {
      formData.append('photo', photo as Blob);
    }

    formData.append('description', description);
    formData.append('year', year);
    formData.append('isApproximateYear', isApproximateYear ? '1' : '0');
    formData.append('personId', props.personId);

    if (props.historyId) {
      formData.append('personHistoryId', props.historyId);
    }

    await PersonPhotoService.add(formData).then(({ data }: any) => {
      if (data.errors) {
        showErrors(data.errors);
        return;
      }

      if (data.success) {
        setSuccess('Успешно добавили');
        props.handleLoadPhotos();
        clearFormState();
      }
    }).catch((error) => {
      setError(error?.response?.data?.message);
    });
  };

  const showErrors = (errors: any) => {
    let msg: any = [];
    errors.forEach((error: any) => {
      msg.push(error[Object.keys(error)[0]]);
    });

    setError(msg.join(', '));
  };

  const handlePhotoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setPhoto(e.target.files[0]);
    }
  };

  const clearFormState = () => {
    setDescription('');
    setYear('');
    setIsApproximateYear(false);
    setPhoto('');
    setError('');
  };

  return (
    <div>
      <div>
        <h3 className="mb-4 text-xl font-medium text-gray-900">
          Добавить фото
        </h3>

        {error && <Alert text={error} />}
        {success && <Alert text={success} />}

        <form
          className="space-y-6"
          action="#"
          method="POST"
          onSubmit={handleAdd}
        >
          <div>
            <label
              htmlFor="description"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Описание
            </label>
            <input
              type="text"
              name="description"
              id="description"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Описание фото"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
          </div>
          <div>
            <label
              htmlFor="year"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Дата события
            </label>
            <input
              type="date"
              name="year"
              id="year"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Ivan Petrovich"
              onChange={(e) => setYear(e.target.value)}
              value={year}
            />
          </div>
          <div>
            <input
              id="yearApprox"
              onChange={() => setIsApproximateYear(!isApproximateYear)}
              checked={isApproximateYear}
              type="checkbox"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
            />
            <label
              htmlFor="yearApprox"
              className="ml-2 text-sm font-medium text-gray-900"
            >
              Дата события является неточной
            </label>
          </div>
          <div>
            <label
              className="block mb-2 text-sm font-medium text-gray-900"
              htmlFor="user_photo"
            >
              Картинка
            </label>
            <input
              className="block w-full text-sm text-gray-900 bg-gray-50 p-2 rounded-lg border border-gray-300 cursor-pointer"
              id="user_photo"
              type="file"
              accept="image/png, image/jpeg"
              onChange={handlePhotoChange}
            />
          </div>

          <button
            type="submit"
            className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Добавить фото
          </button>
        </form>
      </div>
    </div>
  );
}
