import { UsersAction, UsersActionEnum, UsersState } from './types';

const initialState: UsersState = {
  users: [],
  isLoading: false,
  error: '',
  successCreate: false,
  showEditModal: false,
};

export default function usersReducer(
  state = initialState,
  action: UsersAction,
): UsersState {
  switch (action.type) {
    case UsersActionEnum.SET_USERS:
      return { ...state, users: action.payload };
    case UsersActionEnum.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case UsersActionEnum.SET_ERROR:
      return { ...state, error: action.payload };
    case UsersActionEnum.SET_SUCCESS_CREATE:
      return { ...state, successCreate: action.payload };
    case UsersActionEnum.SET_EDIT_MODAL:
      return { ...state, showEditModal: action.payload };
    default:
      return state;
  }
}
