import {
  UsersActionEnum,
  SetUsersAction,
  SetIsLoadingAction,
  SetErrorAction,
  SetSuccessCreateAction,
  SetEditModalAction,
} from './types';
import { AppDispatch } from '../../index';
import UserService from '../../../api/UserService';
import { AuthActionCreators } from '../auth/action-creators';

export interface ResponseCreateUser {
  id: number;
  group: string;
}

export const UsersActionCreators = {
  setUsers: (users: any): SetUsersAction => ({
    type: UsersActionEnum.SET_USERS,
    payload: users,
  }),
  setIsLoading: (payload: boolean): SetIsLoadingAction => ({
    type: UsersActionEnum.SET_IS_LOADING,
    payload,
  }),
  setError: (payload: string): SetErrorAction => ({
    type: UsersActionEnum.SET_ERROR,
    payload,
  }),
  setSuccessCreate: (payload: boolean): SetSuccessCreateAction => ({
    type: UsersActionEnum.SET_SUCCESS_CREATE,
    payload,
  }),
  showUserModal: (payload: boolean): SetEditModalAction => ({
    type: UsersActionEnum.SET_EDIT_MODAL,
    payload,
  }),
  loadUsers: () => (dispatch: AppDispatch) => {
    dispatch(UsersActionCreators.setIsLoading(true));
    UserService.getList()
      .then(({ data }) => {
        dispatch(UsersActionCreators.setUsers(data));
      })
      .catch((err) => {
        console.log(err);
        AuthActionCreators.logout();
      })
      .finally(() => {
        dispatch(UsersActionCreators.setIsLoading(false));
      });
  },
  createUser:
    (username: string, password: string, group = 'manager') =>
    (dispatch: AppDispatch) => {
      dispatch(UsersActionCreators.setIsLoading(true));
      dispatch(UsersActionCreators.setError(''));
      dispatch(UsersActionCreators.setSuccessCreate(false));

      UserService.createUser(username, password, group)
        .then(({ data }) => {
          UsersActionCreators.loadUsers();

          if (data.id) {
            dispatch(UsersActionCreators.setSuccessCreate(true));
          }
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            dispatch(
              UsersActionCreators.setError(response.data.errors.join(', ')),
            );
          }

          if (response.data.message) {
            dispatch(UsersActionCreators.setError(response.data.message));
          }
        })
        .finally(() => {
          dispatch(UsersActionCreators.setIsLoading(false));
        });
    },
};
