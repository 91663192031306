import Moment from 'moment';
import PersonPhotoService from '../../../../api/PersonPhotoService';
import EditableInput from '../../../common/EditableInput';

export default function PersonPhotoList({
  handleLoadPhotos,
  photos,
  inHistory = false,
  handleAddToHistory,
}: any) {

  const handleDelete = (id: number) => {
    PersonPhotoService.delete(id).then(() => {
      handleLoadPhotos();
    });
  };

  const handleChangeDescription = (id: number | string, value: string) => {
    updateSingleField(id, 'description', value);
  };

  const handleChangeDate = async (id: number | string, value: string) => {
    await updateSingleField(id, 'year', value);
  };

  const updateSingleField = async (id: number | string, key: string, value: string) => {
    const photo = photos.data.find((photo: any) => photo.id.toString() === id.toString());
    if (!photo) return;
    const updatedPhoto = { ...photo, [key]: value };

    return PersonPhotoService
      .update(updatedPhoto).then(() => {
        handleLoadPhotos()
      })
      .catch((error) => alert(`Не удалось обновить информацию фотографии.\n${error}`));
  };

  if (!photos.data) return <></>;

  const listItems = photos.data.map((el: any) => {
    return (
      <tr
        key={el.id}
        className="bg-white border-b"
      >
        <td>
          <img
            className="w-24 h-24 object-cover"
            src={
              el.photo
                ? `${process.env.REACT_APP_PERSONS_PHOTO_STATIC}${el.photo}`
                : `${process.env.REACT_APP_COMMON_STATIC}placeholder.jpg`
            }
            alt={el.givenNames}
          />
        </td>
        <td className="py-4 px-6 text-center ">
          <EditableInput
            value={el.description}
            id={el.id}
            onUpdate={handleChangeDescription}
          />
        </td>
        <td className="py-4 px-6 text-center">
          <EditableInput
            value={Moment(el.year).format('DD.MM.Y')}
            id={el.id}
            onUpdate={handleChangeDate}
          />
        </td>
        <td className="py-4 px-6 text-right">
          {
            inHistory ? 
            <button
              className="font-medium text-green-600  hover:underline"
              onClick={() => handleAddToHistory(el.id)}
            >
              Добавить в историю
            </button> : 
            <button
              className="font-medium text-red-600  hover:underline"
              onClick={() => handleDelete(el.id)}
            >
              Удалить
            </button>
          }
        </td>
      </tr>
    );
  });

  return (
    <div className="overflow-x-auto relative mt-4">
      <div className='font-bold text-lg mb-3'>Список фотографий</div>
      {photos.data.length ? '' : 'Фотографии не загружены' }
      <table className="w-full text-sm text-left text-gray-500">
        <thead>
          <th>Фото</th>
          <th className='text-center'>Комментарий</th>
          <th className='text-center'>Дата события</th>
          <th className='text-right'>Действия</th>
        </thead>
        <tbody>{listItems}</tbody>
      </table>
      {/* <Pagination {...photos} setPage={handleSetPage} /> */}
    </div>
  );
}
