import { IUser } from '../../../models/IUser';

export interface UsersState {
  users: IUser[];
  isLoading: boolean;
  error: string;
  successCreate: boolean;
  showEditModal: boolean;
}

export enum UsersActionEnum {
  SET_USERS = 'SET_USERS',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_ERROR = 'SET_ERROR',
  SET_SUCCESS_CREATE = 'SET_SUCCESS_CREATE',
  SET_EDIT_MODAL = 'SET_EDIT_MODAL',
}

export interface SetUsersAction {
  type: UsersActionEnum.SET_USERS;
  payload: IUser[];
}

export interface SetIsLoadingAction {
  type: UsersActionEnum.SET_IS_LOADING;
  payload: boolean;
}

export interface SetErrorAction {
  type: UsersActionEnum.SET_ERROR;
  payload: string;
}

export interface SetSuccessCreateAction {
  type: UsersActionEnum.SET_SUCCESS_CREATE;
  payload: boolean;
}

export interface SetEditModalAction {
  type: UsersActionEnum.SET_EDIT_MODAL;
  payload: boolean;
}

export type UsersAction =
  | SetUsersAction
  | SetIsLoadingAction
  | SetErrorAction
  | SetSuccessCreateAction
  | SetEditModalAction;
