import { AxiosResponse } from 'axios';
import SafeAxios from '../config/safe-axios';
import { IPersonHistory } from '../models/IPersonHistory';

export default class PersonHistoryService {
  static async getList(page = 1, filter: any): Promise<AxiosResponse> {
    return SafeAxios.get('/person-history', {
      params: {
        page,
        ...filter,
      },
    });
  }

  static async create(params: any): Promise<AxiosResponse> {
    return SafeAxios.post<IPersonHistory>('/person-history/create', params);
  }

  static async delete(id: number): Promise<AxiosResponse> {
    return SafeAxios.get<IPersonHistory>(`/person-history/delete/${id}`);
  }

  static async detail(id: number): Promise<AxiosResponse> {
    return SafeAxios.get(`/person-history/detail/${id}`);
  }

  static async update(params: any): Promise<AxiosResponse> {
    return SafeAxios.post(`/person-history/update`, params);
  }
}
