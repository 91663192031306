import { FC, useEffect, useState } from 'react';
import PersonHistoryService from '../../api/PersonHistoryService';
import Pagination from '../../modules/common/Pagination';
import { IPersonHistory } from '../../models/IPersonHistory';
import PublicHistoryItem from '../../modules/public/PublicHistoryItem';

const PublicHistories: FC = () => {
  const [histories, setHistories] = useState({data: []} as any);

  useEffect(() => {
    loadHistory();
  }, []);

  const handleSetPage = (page: number) => {
    loadHistory(page);
  };

  const loadHistory = (page = 1) => {
    PersonHistoryService.getList(page, {ownerId: true}).then(({ data }) => {
      setHistories(data);
    });
  };
  
  if (histories.data?.length === 0) return <></>;

  const historyItems = histories.data.map((el: IPersonHistory) => {
    return (
      <PublicHistoryItem
        history={el}
        accessEdit={false}
        personId={el.id}
        key={el.id}
      />
    );
  });

  return (
    <div className="container mx-auto px-2 pb-10 bg-gray-100">
      <div className="flex flex-wrap justify-between items-center mx-auto">
        <div className="relative mt-4 m-auto p-6 w-full  lg:w-2/4">
          <h1 className="mb-4 mt-6 font-extrabold tracking-tight leading-none text-gray-900 text-2xl ">
            Лента историй
          </h1>
          <div className="grid gap-4"> {historyItems}</div>
          <Pagination {...histories} setPage={handleSetPage} />
        </div>
      </div>
    </div>
  );


};

export default PublicHistories;
