import { FC, useState } from 'react';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { Link, useNavigate } from 'react-router-dom';
import { useActions } from '../../hooks/useActions';
import { UserGroups } from '../../types';

const Navbar: FC = () => {
  const { isAuth, user } = useTypedSelector((state) => state.auth);
  const [menuShow, setMenuShow] = useState(false);
  const { logout } = useActions();
  let navigate = useNavigate();

  const isAdmin = UserGroups.admin === user.group;
  const isManager = UserGroups.manager === user.group;
  const isGuest = UserGroups.guest === user.group;

  const toggleMenu = () => {
    setMenuShow(!menuShow);
  };

  const toLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded ">
      <div className="container px-2 flex flex-wrap justify-between items-center mx-auto">
        <img
          src={require('../../assets/logotype.svg').default}
          className="mr-3 h-6 sm:h-9"
          alt="Thymamai"
          title="Thymamai"
        />
        <button
          onClick={toggleMenu}
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div
          className={
            menuShow
              ? 'block w-full md:block md:w-auto '
              : 'hidden w-full md:block md:w-auto'
          }
        >
          <ul className="flex flex-col p-4 mt-4 bg-gray-50 rounded-lg border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
            {isAuth && (
              <>
                {isManager && (
                  <li>
                    <Link
                      to="/cabinet/accesses"
                      className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
                    >
                      Доступы
                    </Link>
                  </li>
                )}
                {(isManager || isAdmin) && (
                <li>
                  <Link
                    to="/cabinet/person"
                    className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
                  >
                    Профили
                  </Link>
                </li>
                )}
                {isAdmin && (
                  <li>
                    <Link
                      to="/cabinet/user"
                      className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
                    >
                      Пользователи
                    </Link>
                  </li>
                )}
                {isGuest && (
                  <>
                  <li>
                    <Link
                      to="/histories/"
                      className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
                    >
                      Лента историй
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/photos/"
                      className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
                    >
                      Лента фотографий
                    </Link>
                  </li>
                  </>
                )}
                <li>
                  <button
                    onClick={toLogout}
                    className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
                  >
                    Выйти
                  </button>
                </li>
              </>
            )}

            {!isAuth && (
              <li>
                <Link
                  to="/login"
                  className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
                >
                  Войти
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
