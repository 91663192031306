import { FC, useEffect, useState } from 'react';
import useDebounce from '../../../../hooks/useDebounce';

interface PersonAutocompleteInputProps {
  handleQueryInput: Function;
  placeholder: string;
}

const PersonAutocompleteInput: FC<PersonAutocompleteInputProps> = ({
  handleQueryInput,
  placeholder,
  delay = 1500,
}: any) => {
  const [query, setQuery] = useState('');
  const debouncedSearchQuery = useDebounce(query, delay);

  useEffect(() => {
    if (debouncedSearchQuery) {
      handleQueryInput(query);
    }
  }, [debouncedSearchQuery]);

  // @TODO сделать libs с компонентами и сделать его универсальным
  return (
    <div>
      <input
        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 `}
        type="text"
        onChange={(event) => setQuery(event.target.value)}
        placeholder={placeholder}
      />
    </div>
  );
};

export default PersonAutocompleteInput;
