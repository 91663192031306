import { FC, useEffect, useState } from 'react';
import PersonService from '../../api/PersonService';
import {GridPersonPhotos} from '../../modules/public/GridPersonPhotos';

const PublicPhotos: FC = () => {
  const [persons, setPersons] = useState([]) as any;

  useEffect(() => {
    loadPersons()
  }, []);

  const loadPersons = async () => {
    const { data } = await PersonService.getForGuest();
    
    setPersons(data.data);
  }

  if (!persons || persons.length === 0) {
    return <></>;
  }

  return (
    <div className="container mx-auto px-2 pb-10 bg-gray-100">
      <div className="flex flex-wrap justify-between items-center mx-auto">
        <div className="relative mt-4 m-auto p-6 w-full sm:w-2/4">
          <h1 className="mb-4 mt-6 font-extrabold tracking-tight leading-none text-gray-900 text-2xl ">
            Лента фотографий
          </h1>
          <GridPersonPhotos persons={persons}></GridPersonPhotos>
        </div>
      </div>
    </div>
  )
};

export default PublicPhotos;
