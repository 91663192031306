import 'photoswipe/dist/photoswipe.css';
import Moment from 'moment';

import { Gallery, Item } from 'react-photoswipe-gallery';

const LightBox = (props) => (
  <Gallery >
    <div className={` ${props.wrapperClasses || ''}`}>
    {props.images.map((image, index) => {
      const imageSrc = `${process.env.REACT_APP_PERSONS_PHOTO_STATIC}${image.photo}`;

      return (
        <Item
          original={imageSrc}
          thumbnail={imageSrc}
          width="1024"
          height="768"
          key={index} 
        >
          {({ ref, open }) => (
            <div>
              <img
                className={`object-cover object-center cursor-pointer ${props.imgClasses}`}
                ref={ref}
                onClick={open}
                src={imageSrc}
                alt='Картинка'
              />
              <div className="text-center text-xs text-gray-500">
                {Moment(image.year).format('DD.MM.Y')}
              </div>
            </div>
          )}
        </Item>
      );
    })}
    </div>
  </Gallery>
);

export default LightBox;
