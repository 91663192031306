export interface PersonsState {
  persons: any;
  isLoading: boolean;
  error: string;
  successCreate: boolean;
  showEditModal: boolean;
}

export enum PersonsActionEnum {
  SET_PERSONS = 'SET_PERSONS',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_ERROR = 'SET_ERROR',
  SET_SUCCESS_CREATE = 'SET_SUCCESS_CREATE',
  SET_EDIT_MODAL = 'SET_EDIT_MODAL',
}

export interface SetPersonsAction {
  type: PersonsActionEnum.SET_PERSONS;
  payload: any;
}

export interface SetIsLoadingAction {
  type: PersonsActionEnum.SET_IS_LOADING;
  payload: boolean;
}

export interface SetErrorAction {
  type: PersonsActionEnum.SET_ERROR;
  payload: string;
}

export interface SetSuccessCreateAction {
  type: PersonsActionEnum.SET_SUCCESS_CREATE;
  payload: boolean;
}

export interface SetEditModalAction {
  type: PersonsActionEnum.SET_EDIT_MODAL;
  payload: boolean;
}

export type PersonsAction =
  | SetPersonsAction
  | SetIsLoadingAction
  | SetErrorAction
  | SetEditModalAction
  | SetSuccessCreateAction;
