import { AxiosResponse } from 'axios';
import unsafeRequest from '../config/unsafe-axios';
import { ILoginUser } from '../models/ILoginUser';

export default class AuthService {
  static async login(
    username: string,
    password: string,
  ): Promise<AxiosResponse> {
    return unsafeRequest.post<ILoginUser>('/auth/login', {
      email: username,
      password,
    });
  }

  static async guestAuth(hash: string): Promise<AxiosResponse> {
    return unsafeRequest.post<ILoginUser>('/auth/guest-hash', {
      hash,
    });
  }
}
