import { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import HistoryList from '../../../modules/manager-cabinet/components/histories/HistoryList';
import PersonAutocompleteInput from '../../../modules/manager-cabinet/components/persons/PersonAutocompleteInput';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import Spinner from '../../../modules/common/Spinner';
import { useNavigate } from 'react-router-dom';
import PersonService from '../../../api/PersonService';

const History: FC = () => {
  const { isLoading } = useTypedSelector((state) => state.persons);
  const { isAuth } = useTypedSelector((state) => state.auth);
  const [person, setPerson] = useState({
    id: null,
    surname: '',
    givenNames: '',
  });
  let navigate = useNavigate();
  const { personId } = useParams();

  useEffect(() => {
    if (isAuth) {
      PersonService.getPerson(Number(personId)).then(({ data }) => {
        setPerson(data);
      });
    } else {
      navigate('/login', { replace: true });
    }
  }, []);

  if (!person.id) {
    return <></>;
  }

  return (
    <div className="container mx-auto px-2 pb-10">
      <div className="flex flex-wrap justify-between items-center mx-auto flex-col sm:flex-row">
        <div>
          <Link to={`/cabinet/person/`} className="underline">
            Назад
          </Link>
          <h1 className="mb-4 text-xl font-extrabold tracking-tight leading-none text-gray-900 md:text-2xl lg:text-3xl">
            Истории: {person.surname} {person.givenNames}
          </h1>
        </div>
        <Link
          to={`/cabinet/person-history/${person.id}/add`}
          type="button"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0  w-full sm:w-auto"
        >
          Добавить новую историю
        </Link>
      </div>
      {isLoading ? <Spinner /> : <HistoryList />}
    </div>
  );
};

export default History;
