import axios from 'axios';

const unsafeRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL ?? 'http://localhost:5000/',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

export default unsafeRequest;
