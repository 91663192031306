import { AxiosResponse } from 'axios';
import SafeAxios from '../config/safe-axios';
import { IPersonHistory } from '../models/IPersonHistory';

export default class HistoryPhotoService {
  static async getList(historyId: number): Promise<AxiosResponse> {
    return SafeAxios.get(`/history-photo/${historyId}`);
  }

  static async create(params: any): Promise<AxiosResponse> {
    return SafeAxios.post<IPersonHistory>('/history-photo/create', params);
  }

  static async delete(params: any): Promise<AxiosResponse> {
    return SafeAxios.post<IPersonHistory>(`/history-photo/delete/`, params);
  }

  static async updateSort(params: any): Promise<AxiosResponse> {
    return SafeAxios.post(`/history-photo/update-sort`, params);
  }
}
