import { FC } from 'react';
import LoginForm from '../modules/auth/components/LoginForm';

const Login: FC = () => {
  return (
    <div className="flex min-h-full items-start justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
