import { FC, useEffect } from 'react';

import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useNavigate } from 'react-router-dom';

const Index: FC = () => {
  const { isAuth } = useTypedSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth) {
      navigate('/login', { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  return (
    <div className="container mx-auto px-2 pb-10">
      <div className="flex flex-wrap justify-between items-center mx-auto">
        <h1 className="mb-4 text-xl font-extrabold tracking-tight leading-none text-gray-900 md:text-2xl lg:text-3xl ">
          Личный кабинет
        </h1>
      </div>
      <p>Скоро здесь будет интересно.</p>
    </div>
  );
};

export default Index;
