import Moment from 'moment';
import GuestService from '../../../../api/GuestService';

export default function GuestList(props: any) {
  
  const handleDelete = (id: number) => {
    GuestService.deleteGuest(id).then(({ data }: any) => {
      if (data.success) {
        props.handleLoadGuests();
      }
    });
  };

  const clipboardLink = (hash: string) => {
    const link = `${window.location.origin}/guest/${hash}/`;
    navigator.clipboard.writeText(link);
  };

  if (!props.guests) return <></>;

  const listItems = props.guests.map((el: any) => {
    return (
      <tr
        key={el.id}
        className={`${Moment(el.expAt) < Moment() ? 'bg-red-100' : ''} bg-white border-b`}
        data-id={el.id}
      >
        <th
          scope="row"
          className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
        >
           {el.user.email}
        </th>
        <td className="py-4 px-6">{el.lastLoginAt ? Moment(el.lastLoginAt).format('DD.MM.Y HH:mm') : '-'}</td>
        <td className="py-4 px-6">{Moment(el.expAt).format('DD.MM.Y HH:mm')}</td>
        <td className="py-4 px-6  text-right">
          <button
            className="font-medium text-blue-600 hover:underline mr-3"
            onClick={() => clipboardLink(el.hash)}
          >
            Скопировать ссылку
          </button>
          <button
            className="font-medium text-red-600 hover:underline"
            onClick={() => handleDelete(Number(el.id))}
          >
            Удалить
          </button>
        </td>
      </tr>
    );
  });

  return (
    <div className="overflow-x-auto relative mt-4">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
          <tr>
            <th scope="col" className="py-3 px-6">
              Email (аккаунт)
            </th>
            <th scope="col" className="py-3 px-6">
              Последняя авторизация
            </th>
            <th scope="col" className="py-3 px-6">
              Доступ до
            </th>
            <th scope="col" className="py-3 px-6"></th>
          </tr>
        </thead>
        <tbody>{listItems}</tbody>
      </table>
    </div>
  );
}
