import PersonService from '../../../api/PersonService';
import { useEffect, useState } from 'react';
import GroupSibs from './GroupSibs';
import PersonSelf from './common/PersonSelf';

export default function SmallTreeScreen({ person }: any) {
  const [parents, setParents] = useState([]);
  const [sibs, setSibs] = useState([]);
  const [childrens, setChildrens] = useState([]);

  const getAllRelative = (ids: Array<any>, personData: any) => {
    const paramsIds = ids.filter((id) => id);

    PersonService.getChildrens(
      personData.gender === 'male'
        ? { parentMaleId: person.id }
        : { parentFemaleId: person.id },
    ).then(({ data }) => {
      setChildrens(data);
    });

    if (!paramsIds.length) {
      return [];
    }

    PersonService.getParents(paramsIds).then(({ data }) => {
      setParents(data);

      PersonService.getChildrens({
        parentFemaleId: data[1]?.id,
        parentMaleId: data[0]?.id,
      }).then(({ data }) => {
        setSibs(
          data.filter((findedPerson: any) => {
            return findedPerson.id !== personData.id;
          }),
        );
      });
    });
  };

  useEffect(() => {
    setParents([]);
    setChildrens([]);
    setSibs([]);
    getAllRelative([person.parentFemaleId, person.parentMaleId], person);
  }, [person]);

  return (
    <div>
      <section className="px-2 py-12 bg-gray-200 md:px-0">
        <div className="container mx-auto flex flex-col items-center ">
          <GroupSibs persons={parents} />

          <div className=" mx-auto flex justify-center my-5  ">
            <div className="w-1/3 flex items-center justify-end">
              <GroupSibs persons={sibs} />
            </div>
            <div className="w-2/3">
              <PersonSelf person={person} />
            </div>
          </div>

          <GroupSibs persons={childrens} />
        </div>
      </section>
    </div>
  );
}
