import { FC, useEffect, useState } from 'react';
import ManagerList from '../../modules/manager-cabinet/components/guests/GuestList';
import ManagerModal from '../../modules/manager-cabinet/components/guests/GuestModal';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import Spinner from '../../modules/common/Spinner';
import { useNavigate } from 'react-router-dom';
import GuestService from '../../api/GuestService';

const Accesses: FC = () => {
  const [isLoading, setSetIsLoading] = useState(false); 
  const [showModal, setShowModal] = useState(false); 
  const [guests, setGuests] = useState(false); 
  const { isAuth } = useTypedSelector((state) => state.auth);
  let navigate = useNavigate();

  useEffect( ()  =>  {
    if (!isAuth) {
      navigate('/login', { replace: true });
      return;
    } 

    loadGuests();
  }, []);

  const loadGuests = async () => {
    setSetIsLoading(true);
    GuestService.getList().then(({ data }) => {
        setGuests(data);
    }).then(() => {
      setSetIsLoading(false);
    })
  }

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => { 
    setShowModal(false);
  };

  return (
    <div className="container mx-auto px-2 pb-10">
      <div className="flex flex-wrap justify-between items-center mx-auto">
        <h1 className="mb-4 text-xl font-extrabold tracking-tight leading-none text-gray-900 md:text-2xl lg:text-3xl ">
          Список выданных доступов
        </h1>
        <button
          onClick={openModal}
          type="button"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 "
        >
          Добавить пользователя
        </button>
      </div>
      <ManagerModal showModal={showModal} closeModal={closeModal} handleLoadGuests={loadGuests} />
      {isLoading ? <Spinner /> : <ManagerList handleLoadGuests={loadGuests} guests={guests} />}
    </div>
  );
};

export default Accesses;
