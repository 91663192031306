import Moment from 'moment';
import { useEffect } from 'react';
import HistoryPhotoService from '../../../../api/HistoryPhotoService';
import { ArrowLongDownIcon, ArrowLongUpIcon } from '@heroicons/react/20/solid';
import { IHistoryPhoto } from '../../../../models/IHistoryPhoto';
import useDebounce from '../../../../hooks/useDebounce';

const DIRECTION = {
  UP: 'up',
  DOWN: 'down',
};

export default function HistoryPhotoList({ 
  historyId,
  photos,
  handleLoadPhotos,
  handleOpenModal
}: any) {

  useEffect(() => {
    if (historyId) {
      handleLoadPhotos();
    }
  }, []);


  const handleDelete = (id: number) => {
    HistoryPhotoService.delete({
      personHistoryId: historyId,
      personPhotoId: id,
    }).then(() => {
      handleLoadPhotos();
    });
  };

  const handleMassUpdate = (resortPhotos: any) => {
    for (let i = 0; i < resortPhotos.length; i++) {
      HistoryPhotoService.updateSort({
        personHistoryId: historyId,
        personPhotoId: resortPhotos[i].id,
        sort: resortPhotos[i].sort,
      }).then(() => {
        handleLoadPhotos();
      });;
    }
  };

  /**
   * @doc пересчитываем сортировку у каждого элемента, валидация базовая здесь же с разными условиями
   */
  const resortPhotos = (id: number, direction: string) => {
    const totalPhotos = photos.length;
    const mutablePhotos: any = [];

    if (!totalPhotos) return;
    if (totalPhotos === 1) {
      handleMassUpdate({
        personHistoryId: historyId,
        personPhotoId: id,
        sort: 100,
      });
      return;
    }

    if (direction === DIRECTION.UP && photos[0].personPhotoId === id) {
      return
    }

    if (direction === DIRECTION.DOWN && photos[totalPhotos - 1].personPhotoId === id) {
      return
    }
    
    photos.forEach((photo: any, photoIndex: number) => {
      mutablePhotos.push({
        sort: (photoIndex + 1) * 100,
        id: photo.personPhotoId,
      });
    })

    for (let i = 0; i < mutablePhotos.length; i++) {
      if (mutablePhotos[i].id === id) {
        if (direction === DIRECTION.UP) {
          mutablePhotos[i].sort -= 100;
          mutablePhotos[i - 1].sort += 100;
        } else {
          mutablePhotos[i].sort += 100;
          mutablePhotos[i + 1].sort -= 100;
        }
      }
    }

    handleMassUpdate(mutablePhotos);
  };

  if (!photos) return <></>;

  const listItems = photos.map(({personPhoto}: any, index: number) => {
    return (
      <tr
        key={personPhoto.id}
        className="bg-white border-b"
      >
        <td>№{index + 1}</td>
        <td>
          <img
            className="w-24 h-24 object-cover"
            src={
              personPhoto.photo
                ? `${process.env.REACT_APP_PERSONS_PHOTO_STATIC}${personPhoto.photo}`
                : `${process.env.REACT_APP_COMMON_STATIC}placeholder.jpg`
            }
            alt={personPhoto.givenNames}
          />
        </td>
        <td className="py-4 px-6 text-center">
          {personPhoto.description}
        </td>
        <td className="py-4 px-6 text-center">
          {Moment(personPhoto.year).format('DD.MM.Y')}
        </td>
        <td className="py-4 px-6 text-right">
          <button
            className="font-medium text-gray-600  hover:underline"
            title='Изменить сортировку'
            onClick={() => resortPhotos(personPhoto.id, DIRECTION.UP)}
          >
            <ArrowLongUpIcon className="w-6 h-6" />
          </button>
        </td> 
        <td className="py-4 px-6 text-right">
          <button
            title='Изменить сортировку'
            className="font-medium text-gray-600  hover:underline"
            onClick={() => resortPhotos(personPhoto.id, DIRECTION.DOWN)}
          >
            <ArrowLongDownIcon className="w-6 h-6" />
          </button>
        </td> 
        <td className="py-4 px-6 text-right">
          <button
            onClick={() => handleDelete(personPhoto.id)}
            className="font-medium text-red-600  hover:underline"
          >
            Удалить
          </button>
        </td>
      </tr>
    );
  });

  return (
    <div>
      <div className='font-bold text-lg mb-3'>Список фотографий</div>
      {photos.length ? '' : 'Фотографии не загружены' }
      <table className="w-full text-sm text-left text-gray-500">
        <tbody>{listItems}</tbody>
      </table>
      <button onClick={handleOpenModal} className=' text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-3'>Добавить фотографии из профиля</button>
    </div>
  );
}
