import { FC } from 'react';
import { Link } from 'react-router-dom';
import PersonForm from '../../../modules/manager-cabinet/components/persons/PersonForm';
import TooltipText from './TooltipText';

const PersonAdd: FC = () => {
  return (
    <div className="container mx-auto px-2 pb-10">
      <Link
        to="/cabinet/person"
        className="font-medium text-blue-600 hover:underline"
      >
        Назад к списку
      </Link>
      <div className="sm:w-2/4 w-full mt-3">
        <PersonForm />
      </div>
      <TooltipText />
    </div>
  );
};

export default PersonAdd;
