import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PersonList from '../../../modules/manager-cabinet/components/persons/PersonList';
import PersonAutocompleteInput from '../../../modules/manager-cabinet/components/persons/PersonAutocompleteInput';
import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import Spinner from '../../../modules/common/Spinner';
import { useNavigate } from 'react-router-dom';

const Person: FC = () => {
  const { loadPersons } = useActions();
  const { isLoading } = useTypedSelector((state) => state.persons);
  const { isAuth } = useTypedSelector((state) => state.auth);
  let navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      loadPersons();
    } else {
      navigate('/login', { replace: true });
    }
  }, []);

  const handleQueryInput = (query: string) => {
    const startPage = 1;
    loadPersons(startPage, query);
  };

  return (
    <div className="container mx-auto px-2 pb-10">
      <div className="flex flex-wrap justify-between items-center mx-auto flex-col sm:flex-row">
        <h1 className="mb-4 text-xl font-extrabold tracking-tight leading-none text-gray-900 md:text-2xl lg:text-3xl ">
          Список профилей
        </h1>
        <Link
          to="/cabinet/person/add"
          type="button"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0  w-full sm:w-auto"
        >
          Добавить новый профиль
        </Link>
      </div>
      <div className="my-3">
        <PersonAutocompleteInput
          handleQueryInput={handleQueryInput}
          placeholder="Поиск по людям, ФИО, даты"
        />
      </div>
      {isLoading ? <Spinner /> : <PersonList />}
    </div>
  );
};

export default Person;
