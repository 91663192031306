import { IPersonPhoto } from '../../../models/IPersonPhoto';
import Moment from 'moment';

const PreviewPhotos = (props: any) => {
  if (!props.personPhotos.data) return <></>;

  return (
    <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
      {props.personPhotos.data.map((item: IPersonPhoto, itemIndex: number) => {
        if (itemIndex > 1) return;
        return (
          <div key={itemIndex} className="text-center mb-4">
            <img
              alt={item.year}
              className="block h-full w-full rounded-lg object-cover object-center"
              src={
                item.photo
                  ? `${process.env.REACT_APP_PERSONS_PHOTO_STATIC}${item.photo}`
                  : `${process.env.REACT_APP_COMMON_STATIC}placeholder.jpg`
              }
            />
              <span className="text-xs text-gray-500">
                {Moment(item.year).format('DD.MM.Y')}
              </span>
          </div>
        );
      })}
    </div>
  );
};

export default PreviewPhotos;
