import React, { useEffect, useState } from 'react';
import { useActions } from '../../../../hooks/useActions';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import Alert from '../../../common/Alert';
import Autocomplete from '../../../common/autocomplete/AutocompleteInput';
import PersonService from '../../../../api/PersonService';
import { GenderTypes, TYPE_FEMALE, TYPE_MALE } from '../../types';
import { useNavigate } from 'react-router-dom';

export default function PersonForm(props: any) {
  let navigate = useNavigate();

  const [status, setStatus] = useState('active');
  const [gender, setGender] = useState('male');
  const [surname, setSurname] = useState('');
  const [givenNames, setGivenNames] = useState('');
  const [birthAt, setBirthAt] = useState('');
  const [isApproximateBirth, setIsApproximateBirth] = useState(false);
  const [deathAt, setDeathAt] = useState('');
  const [isApproximateDeath, setIsApproximateDeath] = useState(false);
  const [birthPlace, setBirthPlace] = useState('');
  const [avatar, setAvatar] = useState({});
  const [avatarEdit, setAvatarEdit] = useState(''); // Чтобы держать строку и наличие картинки при редактировании, основная работа все равно с основным аватаром

  const [parentMale, setParentMale] = useState('');
  const [parentFemale, setParentFemale] = useState('');

  const [parentMaleId, setParentMaleId] = useState(0);
  const [parentFemaleId, setParentFemaleId] = useState(0);

  const [errorAvatar, setErrorAvatar] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [suggestMale, setSuggestMale] = useState([]);
  const [suggestFemale, setSuggestFemale] = useState([]);

  const { user } = useTypedSelector((state) => state.auth);
  const { loadPersons } = useActions();

  useEffect(() => {
    clearFormState();

    if (props.editId) {
      loadPerson();
    }
  }, [props.editId]);

  const loadPerson = () => {
    PersonService.getPerson(props.editId).then(({ data }) => {
      setPerson(data);
      setParents(data);
    });
  };

  const handleAddOrUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    processAddOrUpdate();
  };

  const processAddOrUpdate = async () => {
    setError('');
    setSuccess('');

    const formData = new FormData();
    if (avatar instanceof File) {
      formData.append('avatar', avatar as Blob);
      formData.append('avatarName', avatarEdit);
    }
    formData.append('userId', user.userId);
    formData.append('status', status);
    formData.append('gender', gender);
    formData.append('surname', surname);
    formData.append('givenNames', givenNames);
    formData.append('birthPlace', birthPlace);
    formData.append('birthAt', birthAt);
    formData.append('isApproximateBirth', isApproximateBirth ? '1' : '0');
    formData.append('deathAt', deathAt);
    formData.append('isApproximateDeath', isApproximateDeath ? '1' : '0');
    formData.append('parentMaleId', parentMaleId.toString());
    formData.append('parentFemaleId', parentFemaleId.toString());

    props.editId
      ? updatePerson(props.editId, formData)
      : createPerson(formData);

    await loadPersons();
  };

  const createPerson = async (formData: FormData) => {
    await PersonService.createPerson(formData).then(({ data }: any) => {
      if (data.errors) {
        showErrors(data.errors);
        return;
      }

      if (data.success) {
        navigate('/cabinet/person', { replace: true });
      }
    });
    clearFormState();
  };

  const updatePerson = async (personId: string, formData: FormData) => {
    formData.append('id', personId);
    await PersonService.editPerson(formData).then(({ data }: any) => {
      if (data.errors) {
        showErrors(data.errors);
        return;
      }

      if (data.success) {
        navigate('/cabinet/person', { replace: true });
      }
    });
  };

  const showErrors = (errors: any) => {
    let msg: any = [];
    errors.forEach((error: any) => {
      msg.push(error[Object.keys(error)[0]]);
    });

    setError(msg.join(', '));
  };

  const handleAvatarChange = async (e: React.ChangeEvent<HTMLInputElement> | any) => {
    if (e.target.files) {
      setAvatar(e.target.files[0]);
      setAvatarEdit(e.target.files[0].name)
    }

    if (props.editId) {
      setErrorAvatar('');

      const formData = new FormData();

      formData.append('avatar', e.target.files[0] as Blob);
      formData.append('avatarName', e.target.files[0].name);
      formData.append('userId', user.userId);
      formData.append('id', props.editId);
      
      await PersonService.updatePersonAvatar(formData).then(({ data }: any) => {
        loadPerson()
      }).catch((error) => {
        setErrorAvatar(error?.response?.data?.message);
      });
    }
  };

  const handleSearchedMale = (query: string) => {
    PersonService.findByQuery(query).then(({ data }) => {
      setSuggestMale(data.data);
    });
  };

  const handleSelectParentMaleValue = (person: any) => {
    setParentMaleId(person.id);
    setSuggestMale([]);
    setParentMale(`${person.surname} ${person.givenNames}`);
  };

  const handleSearchedFemale = (query: string) => {
    PersonService.findByQuery(query).then(({ data }) => {
      setSuggestFemale(data.data);
    });
  };

  const handleSelectParentFemaleValue = (person: any) => {
    setParentFemaleId(person.id);
    setSuggestFemale([]);
    setParentFemale(`${person.surname} ${person.givenNames}`);
  };

  const addUnknownParent = async (gender: GenderTypes) => {
    const { data }: any = await PersonService.createUnknownPerson(
      gender,
      props.editId,
    );

    if (data.success) {
      if (data.unknownPerson.gender === TYPE_MALE) {
        setParentMale(
          `${data.unknownPerson.surname} ${data.unknownPerson.givenNames}`,
        );
        setParentMaleId(data.unknownPerson.id);
      }

      if (data.unknownPerson.gender === TYPE_FEMALE) {
        setParentFemale(
          `${data.unknownPerson.surname} ${data.unknownPerson.givenNames}`,
        );
        setParentFemaleId(data.unknownPerson.id);
      }

      window.location.reload();
    }
  };

  const clearFormState = () => {
    setStatus('public');
    setGender('male');
    setSurname('');
    setGivenNames('');
    setBirthAt('');
    setDeathAt('');
    setAvatar('');
    setAvatarEdit('');
    setBirthPlace('');
    setParentMaleId(0);
    setParentFemaleId(0);

    setError('');
    setSuccess('');
  };

  const setParents = (person: any) => {
    if (person.parentMaleId) {
      PersonService.getPerson(person.parentMaleId).then(({ data }) => {
        setParentMale(`${data.surname} ${data.givenNames}`);
      });
    }

    if (person.parentFemaleId) {
      PersonService.getPerson(person.parentFemaleId).then(({ data }) => {
        setParentFemale(`${data.surname} ${data.givenNames}`);
      });
    }
  };

  const setPerson = (person: any) => {
    setStatus(person.status);
    setGender(person.gender);
    setSurname(person.surname);
    setGivenNames(person.givenNames);
    setBirthPlace(person.birthPlace || '');
    setBirthAt(person.birthAt || '');
    setDeathAt(person.deathAt || '');
    setIsApproximateBirth(!!person.isApproximateBirth);
    setIsApproximateDeath(!!person.isApproximateDeath);
    setParentMaleId(person.parentMaleId || 0);
    setParentFemaleId(person.parentFemaleId || 0);
    setAvatarEdit(person.avatar);
  };

  return (
    <div>
      <div>
        <h3 className="mb-4 text-xl font-medium text-gray-900">
          {props.editId ? 'Редактировать профиль' : 'Добавить профиль'}
        </h3>

        {error && <Alert text={error} />}
        {success && <Alert text={success} />}

        <form
          className="space-y-6"
          action="#"
          method="POST"
          onSubmit={handleAddOrUpdate}
        >
          <div>
            <label
              htmlFor="parentMale"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Отец
            </label>
            <Autocomplete
              handleKeyup={handleSearchedMale}
              handleSelectValue={handleSelectParentMaleValue}
              suggestions={suggestMale}
              defaultValue={parentMale}
            />
            <button
              type="button"
              className="text-red-500"
              onClick={() => setParentMale('')}
            >
              очистить
            </button>
          </div>
          <div>
            <label
              htmlFor="parentFemale"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Мать
            </label>
            <Autocomplete
              handleKeyup={handleSearchedFemale}
              handleSelectValue={handleSelectParentFemaleValue}
              suggestions={suggestFemale}
              defaultValue={parentFemale}
            />
            <button
              type="button"
              className="text-red-500"
              onClick={() => setParentFemale('')}
            >
              очистить
            </button>
          </div>
          <div>
            <label
              htmlFor="surname"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Фамилия *
            </label>
            <input
              type="text"
              name="surname"
              id="surname"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Иванов"
              required
              onChange={(e) => setSurname(e.target.value)}
              value={surname}
            />
          </div>
          <div>
            <label
              htmlFor="givenNames"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Имя, Отчество *
            </label>
            <input
              type="text"
              name="givenNames"
              id="givenNames"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Иван Петрович"
              required
              onChange={(e) => setGivenNames(e.target.value)}
              value={givenNames}
            />
          </div>
          <div>
            <label
              htmlFor="birthPlace"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Место рождения
            </label>
            <input
              type="text"
              name="birthPlace"
              id="birthPlace"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="г. Боровичи"
              onChange={(e) => setBirthPlace(e.target.value)}
              value={birthPlace}
            />
          </div>
          <div>
            <label
              htmlFor="birthAt"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Дата рождения *
            </label>
            <input
              type="date"
              name="birthAt"
              id="birthAt"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Ivan Petrovich"
              required
              onChange={(e) => setBirthAt(e.target.value)}
              value={birthAt}
            />
          </div>
          <div>
            {isApproximateBirth}
            <input
              id="birthApprox"
              onChange={() => setIsApproximateBirth(!isApproximateBirth)}
              checked={isApproximateBirth}
              type="checkbox"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "
            />
            <label
              htmlFor="birthApprox"
              className="ml-2 text-sm font-medium text-gray-900"
            >
              Дата рождения является неточной
            </label>
          </div>
          <div>
            <label
              htmlFor="deathAt"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Дата смерти
            </label>
            <input
              type="date"
              name="deathAt"
              id="deathAt"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Ivan Petrovich"
              onChange={(e) => setDeathAt(e.target.value)}
              value={deathAt}
            />
          </div>
          <div>
            <input
              id="deathApprox"
              onChange={() => setIsApproximateDeath(!isApproximateDeath)}
              checked={isApproximateDeath}
              type="checkbox"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "
            />
            <label
              htmlFor="deathApprox"
              className="ml-2 text-sm font-medium text-gray-900"
            >
              Дата смерти является неточной
            </label>
          </div>
          {/* <div>
            <label
              htmlFor="status"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Статус
            </label>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            >
              <option value="public">public</option>
              <option value="private">private</option>
            </select>
          </div> */}
          <div>
            <label
              htmlFor="status"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Гендер
            </label>
            <select
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            >
              <option value="male">Мужской</option>
              <option value="female">Женский</option>
              {/* <option value="other">Другой</option> */}
            </select>
          </div>

          <div>
            <label
              className="block mb-2 text-sm font-medium text-gray-900"
              htmlFor="user_avatar"
            >
              Фото
            </label>
              {errorAvatar && <Alert type='warning' text={errorAvatar} />}
            <div className='flex items-center border rounded-xl shadow-sm p-6 dark:bg-slate-800 dark:border-gray-700'>
              {avatarEdit ? (<div>
                  <img
                    className="w-96 p-1 ring-2 ring-gray-300"
                    src={
                      avatarEdit
                        ? `${process.env.REACT_APP_PERSONS_SMALL_STATIC}${avatarEdit}`
                        : `${process.env.REACT_APP_COMMON_STATIC}/placeholder.jpg`
                    }
                    alt="avatar"
                  />
                </div>
              ) : ''}
              <input
                className="block ml-3 w-full text-sm text-gray-900 bg-gray-50 p-2 rounded-lg border border-gray-300 cursor-pointer focus:outline-none "
                id="user_avatar"
                type="file"
                accept="image/png, image/jpeg"
                onChange={handleAvatarChange}
              /> 
            </div>

            {(avatar && !props.editId ? <div className='text-green-500'>Новое фото добавлено, применится после сохранения</div> : '')}
          </div>

          <button
            type="submit"
            className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
          >
            {props.editId ? 'Сохранить профиль' : 'Добавить профиль'}
          </button>

          {/* {props.editId && !parentMaleId && (
            <button
              onClick={() => addUnknownParent(TYPE_MALE)}
              type="button"
              className="w-full text-blue-800 border-solid border-blue-800 border-2 hover:text-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center  "
            >
              Добавить неизвестного отца
            </button>
          )}

          {props.editId && !parentFemaleId && (
            <button
              onClick={() => addUnknownParent(TYPE_FEMALE)}
              type="button"
              className="w-full text-blue-800 border-solid border-blue-800 border-2 hover:text-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center  "
            >
              Добавить неизвестную мать
            </button>
          )} */}
        </form>
      </div>
    </div>
  );
}
