import { FC } from 'react';
import { IPerson } from '../../../models/IPerson';
import ApproximateDate from '../../common/ApproximateDate';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { Link } from 'react-router-dom';
import { UserGroups } from '../../../types';

interface PersonDetailProps {
  person: IPerson;
}

const PersonDetail: FC<PersonDetailProps> = ({ person }) => {
  const { user } = useTypedSelector((state) => state.auth);

  const isGuest = UserGroups.guest === user.group;

  if (!person) {
    return <></>;
  }

  return (
    <div className="text-center mb-10">
      <img
        className="w-32 h-32 rounded-full shadow-lg object-cover m-auto"
        src={
          person.avatar
            ? `${process.env.REACT_APP_PERSONS_SMALL_STATIC}${person.avatar}`
            : `${process.env.REACT_APP_COMMON_STATIC}/placeholder.jpg`
        }
        alt={person.givenNames}
      />
      <h1 className="my-4 text-xl font-extrabold tracking-tight leading-none text-gray-900 md:text-2xl lg:text-3xl">
        {person.surname} {person.givenNames}
      </h1>
      
      <div className="text-gray-500 text-xl">
        <ApproximateDate
          date={person.birthAt}
          isApproximate={person.isApproximateBirth}
        />
        {person.deathAt && ' - '}
        {person.deathAt && (
          <ApproximateDate
            date={person.deathAt}
            isApproximate={person.isApproximateDeath}
          />
        )}
      </div>
      
      {
        !isGuest && (
        <Link
          to={`/cabinet/person/edit/4${person.id}`}
          className="text-blue-500 underline"
        >
          редактировать
        </Link>
        )
      }
    </div>
  );
};

export default PersonDetail;
