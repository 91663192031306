import { FC } from 'react';
import { Link } from 'react-router-dom';

const PageNotFound: FC = () => {
  return (
    <section className="flex items-center h-full p-16 ">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 font-extrabold text-9xl ">
            <span className="sr-only">Ошибка</span>404
          </h2>
          <p className="text-2xl font-semibold md:text-3xl">
            К сожалению мы не смогли найти страницу.
          </p>
          <p className="mt-4 mb-8">
            Воспользуйтесь ссылкой на главную или напшитие нам в техподдержку
            info@thymamai.ru
          </p>
          <Link
            to="/"
            className="px-8 py-3 font-semibold rounded "
          >
            Вернуться на главную
          </Link>
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;
