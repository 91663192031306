import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useActions } from '../../../../hooks/useActions';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import Alert from '../../../common/Alert';
import UserService from '../../../../api/UserService';

export default function ManagerModal(props: any) {
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [group, setGroup] = useState('manager');
  const { showUserModal, loadUsers } = useActions();
  const { showEditModal } = useTypedSelector((state) => state.users);

  const cancelButtonRef = useRef(null);

  const closeModal = () => {
    showUserModal(false);
  };

  useEffect(() => {
    setSuccess('');
    setError('');

    if (props.editId) {
      UserService.getUser(props.editId).then(({ data }) => {
        setUser(data);
      });
    } else {
      clearFormState();
    }
  }, [props.editId]);

  const handleAddOrUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setSuccess('');
    setError('');

    if (props.editId) {
      await UserService.editUser({
        id: props.editId,
        email: email,
        password: password || '',
        group: group || '',
      }).then(({ data }: any) => {
        if (data.errors) {
          showErrors(data.errors);
          return;
        }

        if (data.success) {
          setSuccess('Успешно обновили');
        }
      });
    } else {
      await UserService.createUser(email, password, group).then(
        ({ data }: any) => {
          if (data.errors) {
            showErrors(data.errors);
            return;
          }

          if (data.success) {
            setSuccess('Успешно обновили');
          }
        },
      );
      clearFormState();
    }

    loadUsers();
  };

  const showErrors = (errors: any) => {
    let msg: any = [];
    errors.forEach((error: any) => {
      msg.push(error[Object.keys(error)[0]]);
    });

    setError(msg.join(', '));
  };

  const clearFormState = () => {
    setEmail('');
    setPassword('');
    setGroup('manager');
  };

  const addTest = () => {
    setUser({
      email: 'asd@asd.ru',
      deathAt: 'manager',
      password: '123',
    });
  };

  const setUser = (user: any) => {
    setEmail(user.email);
    setPassword(user.password);
    setGroup(user.group);
  };

  return (
    <div>
      <Transition.Root show={showEditModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <button
                    type="button"
                    className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={closeModal}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                  <div className="py-6 px-6 lg:px-8">
                    <h3 className="mb-4 text-xl font-medium text-gray-900">
                      {props.editId
                        ? 'Редактировать пользователя'
                        : 'Добавить нового пользователя'}
                    </h3>
                    {process.env.REACT_APP_MODE === 'DEV' && (
                      <button
                        onClick={addTest}
                        type="button"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0"
                      >
                        Добавить test
                      </button>
                    )}

                    {error && <Alert text={error} />}
                    {success && <Alert text={success} />}
                    <form
                      className="space-y-6"
                      action="#"
                      method="POST"
                      onSubmit={handleAddOrUpdate}
                    >
                      <div>
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm font-medium text-gray-900 "
                        >
                          Your email
                        </label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          placeholder="name@company.com"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                          value={email || ''}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="password"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          Your password
                        </label>
                        <input
                          type="password"
                          name="password"
                          id="password"
                          placeholder="••••••••"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          required={!props.editId}
                          onChange={(e) => setPassword(e.target.value)}
                          value={password || ''}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="Type"
                          className="block mb-2 text-sm font-medium text-gray-900 "
                        >
                          Type
                        </label>
                        <select
                          value={group}
                          onChange={(e) => setGroup(e.target.value)}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                        >
                          <option value="manager">manager</option>
                          <option value="admin">admin</option>
                        </select>
                      </div>

                      <button
                        type="submit"
                        className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                      >
                        {props.editId
                          ? 'Редактировать пользователя'
                          : 'Добавить пользователя'}
                      </button>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
