import PersonService from '../../../api/PersonService';
import { useEffect, useState } from 'react';
import GroupSibs from './GroupSibs';
import PersonSelf from './common/PersonSelf';
import { useSessionStorageState } from '../../../hooks/useStorage';

const DEEP_TREE = 3;
const REQIRED_ITERATION = 2;

export default function LargeTreeScreen({ person }: any) {
  const [sibs, setSibs] = useState([]);
  const [childrens, setChildrens] = useState([]);
  const [parentTree, setParentTree] = useState([] as any);

  const [peronsMemo, setPeronsMemo] = useSessionStorageState(
    'cacheParentPersons',
    {} as any,
  );
  const localCacheTmp: any = {};

  const getAllRelative = (ids: Array<any>, personData: any) => {
    const paramsIds = ids.filter((id) => id);

    PersonService.getChildrens(
      personData.gender === 'male'
        ? { parentMaleId: person.id }
        : { parentFemaleId: person.id },
    ).then(({ data }) => {
      setChildrens(data);
    });

    if (!paramsIds.length) {
      return [];
    }

    PersonService.getParents(paramsIds).then(({ data }) => {
      const [parentMale] = data.filter((p: any) => p.gender === 'male') || '';
      const [parentFemale] =
        data.filter((p: any) => p.gender === 'female') || '';

      PersonService.getChildrens({
        parentFemaleId: parentFemale?.id,
        parentMaleId: parentMale?.id,
      }).then(({ data }) => {
        setSibs(
          data.map((findedPerson: any) => {
            if (findedPerson.id === personData.id) {
              return { ...findedPerson, ...{ choosen: true } };
            }
            return findedPerson;
          }),
        );
      });
    });
  };

  const getRecursiveParents: any = async (
    params: any,
    counter: number,
    tree: any,
  ) => {
    if (counter >= DEEP_TREE) return tree;

    let localParams: any = [];

    tree.push([]);

    for (const param of params) {
      const paramKeyCache = param.join('_');
      let tmpData;

      if (peronsMemo[paramKeyCache]) {
        tmpData = peronsMemo[paramKeyCache];
      } else {
        const { data } = (await PersonService.getParents(param)) as any;
        tmpData = data;
        localCacheTmp[paramKeyCache] = data;
      }

      let tmpPerson = [];

      for (let index = 0; index < REQIRED_ITERATION; index++) {
        const person = tmpData[index] || [0, 0];
        tmpPerson.push(person);

        localParams.push([
          person.parentMaleId || 0,
          person.parentFemaleId || 0,
        ]);
      }

      tree[counter].push(tmpPerson);
    }

    return await getRecursiveParents(localParams, counter + 1, tree);
  };

  const makeParentTree = async () => {
    let tree = [[]] as any;
    tree = await getRecursiveParents(
      [[person.parentMaleId, person.parentFemaleId]],
      0,
      tree,
    );

    setPeronsMemo({ ...peronsMemo, ...localCacheTmp });
    setParentTree(tree.reverse());
  };

  useEffect(() => {
    if (person.id) {
      getAllRelative([person.parentFemaleId, person.parentMaleId], person);
      setChildrens([]);
      setSibs([]);
      makeParentTree();
    }
  }, [person]);

  const makeParentTreeTemplate = parentTree.map((row: any, index: number) => {
    const persons = row.map((couplePerson: any, childIndex: number) => {
      return (
        <GroupSibs
          persons={couplePerson}
          sibsCountShow={true}
          classNames="w-full sibs-block-parent justify-around"
          uniqIndex={`${index.toString()}-${childIndex.toString()}`}
          key={`child-${index.toString()}-${childIndex.toString()}`}
        />
      );
    });

    return (
      <div
        className="flex justify-center my-2 w-full"
        key={`parent-${index.toString()}`}
      >
        {persons}
      </div>
    );
  });

  return (
    <div className="overflow-auto">
      <section className="px-2 py-12 bg-gray-200 md:px-0 window-tree">
        <div className=" mx-auto flex flex-col items-center ">
          {makeParentTreeTemplate}

          <div className=" mx-auto w-full flex my-5 sibs-block ">
            <div className="w-1/3 mx-5 block ">
              <GroupSibs persons={sibs} key="_hor-sibs" classNames='justify-end overflow-auto' />
            </div>

            <PersonSelf person={person} />
            <div className="w-1/3"></div>
          </div>

          {
            childrens.length > 0 && (
              <GroupSibs
              persons={childrens}
              key="_child-sibs"
              classNames="sibs-block-children"
            />
            )
          }
        </div>
      </section>
    </div>
  );
}
