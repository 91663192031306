import PersonSmallPlaceholder from './common/PersonSmallPlaceholder';
import PersonSmall from './common/PersonSmall';

export default function GroupSibs(props: any) {
  const personsList = props.persons.map((person: any, index: number) => {

    if (!person || !person.id) {
      return <PersonSmallPlaceholder key={`${props.uniqIndex}-${index}`} />;
    }

    return (
      <PersonSmall
        person={person}
        sibsCountShow={props.sibsCountShow}
        key={`_${person.id.toString()}`}
      />
    );
  });

  return (
    <div
      className={`flex  ${
        props.classNames ?? ''
      }`}
    >
      {props.persons.length ? personsList : <PersonSmallPlaceholder />}
    </div>
  );
}
