import HistoryItem from '../../manager-cabinet/components/histories/HistoryItem';
import { useTypedSelector } from '../../../hooks/useTypedSelector';

const PreviewHistory = (props: any) => {
  const { user } = useTypedSelector((state) => state.auth);

  if (!props.personHistory.data) return <></>;

  const listItems = props.personHistory.data.map((el: any, index: number) => {
    if (index > 1) return;

    return (
      <HistoryItem
        history={el}
        accessEdit={Number(user.userId) === Number(props.person.userId) || user.group === 'admin' }
        personId={props.personId}
        key={index}
      />
    );
  });

  return <div className="grid gap-4"> {listItems}</div>;
};

export default PreviewHistory;
