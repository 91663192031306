import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import HistoryForm from '../../../modules/manager-cabinet/components/histories/HistoryForm';
import TooltipText from './TooltipText';

const HistoryAdd: FC = () => {
  const { personId } = useParams();

  return (
    <div className="container mx-auto px-2 pb-10">
      <Link
        to={`/cabinet/person-history/${personId}`}
        className="font-medium text-blue-600  hover:underline"
      >
        Назад к списку
      </Link>
      <div className="flex gap-10 sm:flex-row flex-col">
        <div className="sm:w-2/4 w-full mt-3">
          <HistoryForm />
        </div>
        <div className="sm:w-2/4 w-full mt-3">
        <div className="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300" role="alert">
          <span className="font-medium">Внимание!</span> Чтобы добавить фото или аудиозапись, необходимо добавить историю, а для этого нужно добавить название,превью текста, дату, а затем сохранить.
        </div>
        </div>
      </div>
      <TooltipText />
    </div>
  );
};

export default HistoryAdd;
