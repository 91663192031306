import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import PersonHistoryService from '../../api/PersonHistoryService';
import Pagination from '../../modules/common/Pagination';
import PersonService from '../../api/PersonService';
import { IPerson } from '../../models/IPerson';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import HistoryItem from '../../modules/manager-cabinet/components/histories/HistoryItem';

export default function HistoryList(props: any) {
  const [histories, setHistories] = useState([] as any);
  const [person, setPerson] = useState({} as IPerson);
  const { user } = useTypedSelector((state) => state.auth);

  const { id } = useParams();

  const handleSetPage = (page: number) => {
    loadHistory(page);
  };

  useEffect(() => {
    PersonService.getPublicPerson(Number(id)).then(({ data }) => {
      setPerson(data);
    });
    loadHistory();
  }, []);

  const loadHistory = (page = 1) => {
    PersonHistoryService.getList(page, { personId: id }).then(({ data }) => {
      setHistories(data);
    });
  };

  if (!histories.data) return <></>;

  const historyItems = histories.data.map((el: any) => {
    return (
      <HistoryItem
        history={el}
        accessEdit={Number(user.userId) === Number(person.userId) || user.group === 'admin'}
        personId={id}
        key={id}
      />
    );
  });

  return (
    <div className="container mx-auto px-2 pb-10">
      <div className="flex flex-wrap justify-between items-center mx-auto">
        <div className="overflow-x-auto relative mt-4 m-auto p-6 w-full sm:w-2/4">
          <Link to={`/profile/${id}/`} className="underline">
            Назад
          </Link>
          <h1 className="mb-4 mt-6 text-xl font-extrabold tracking-tight leading-none text-gray-900 md:text-2xl lg:text-3xl ">
            Истории: {person.surname} {person.givenNames}
          </h1>
          <div className="grid gap-4"> {historyItems}</div>
          <Pagination {...histories} setPage={handleSetPage} />
        </div>
      </div>
    </div>
  );
}
