import { AxiosResponse } from 'axios';
import SafeAxios from '../config/safe-axios';
import { IPersonPhoto } from '../models/IPersonPhoto';

export default class PersonPhotoService {
  static async getList(page = 1, params: any): Promise<AxiosResponse> {
    return SafeAxios.get('/person-photo', {
      params: {
        ...params,
        page,
      },
    });
  }

  static async getGroupByYear(personId: Number): Promise<AxiosResponse> {
    return SafeAxios.get(`/person-photo/group-by-year/${personId}`);
  }

  static async add(formData: FormData): Promise<AxiosResponse> {
    return SafeAxios.post<IPersonPhoto>('/person-photo/create', formData);
  }

  static async delete(id: number): Promise<AxiosResponse> {
    return SafeAxios.get<IPersonPhoto>(`/person-photo/delete/${id}`);
  }

  static async detail(id: number): Promise<AxiosResponse> {
    return SafeAxios.get(`/person-photo/detail/${id}`);
  }

  static async update(params: any): Promise<AxiosResponse> {
    return SafeAxios.post(`/person-photo/update/`, params);
  }
}
