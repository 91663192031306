import { FC, useEffect  } from 'react';
import AuthService from '../api/AuthService';
import { useNavigate, useParams } from 'react-router-dom';
import { useActions } from '../hooks/useActions';

const AccessGuest: FC = () => {
  const { hash } = useParams();
  const { loginGuest } = useActions();
  let navigate = useNavigate();

  useEffect(() => {
    if (!hash) {
      navigate('/');
      return;
    }

    AuthService.guestAuth(hash ?? '').then(({ data }: any) => {
      const { token, userId, username, group } = data;
      loginGuest(token, userId, username, group);
      navigate('/histories/');
    });
  }, []);

  return (
    <>  </>
  );

};

export default AccessGuest;
