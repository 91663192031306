import { Link } from 'react-router-dom';

export default function PublicHistoryItem(props: any) {
  return (
    <article
      className="p-4 bg-white max-w-full shadow-emerald-300 
      flex gap-3 items-start flex-col sm:flex-row sm:items-center"
      key={props.history.id}
    >
    <Link
        to={`/profile/${props.personId}/histories/${props.history.id}/`}
        className='min-w-36 h-36 block'
      >
      {
        props.history.photos.length === 0 ? 
        <img
          className="w-36 h-36 bg-slate-400 object-cover"
          src={
            props.history.person.avatar
              ? `${process.env.REACT_APP_PERSONS_SMALL_STATIC}${props.history.person.avatar}`
              : `${process.env.REACT_APP_COMMON_STATIC}/placeholder.jpg`
          }
          alt={props.history.person.givenNames}
        /> :
        <img
          className="w-36 h-36 bg-slate-400 object-cover"
          src={
            `${process.env.REACT_APP_PERSONS_PHOTO_STATIC}${props.history.photos[0].photo}`
          }
          alt={props.history.person.givenNames}
        />
      }
    </Link>
    <div className='flex-grow'>
      <h2 className="text-xl font-bold tracking-tight text-gray-900 ">
        <Link
          to={`/profile/${props.personId}/histories/${props.history.id}/`}
          className="truncate"
        >
          {props.history.title.substring(0, 25) || 'Без заголовка'}
        </Link>
      </h2>
      <div className="flex items-center gap-1 mb-2">
          <Link
              to={`/profile/${props.personId}/histories/${props.history.id}/`}
            >
            <img
              className="w-4 h-4 bg-slate-400 rounded-full object-cover"
              src={
                props.history.person.avatar
                  ? `${process.env.REACT_APP_PERSONS_SMALL_STATIC}${props.history.person.avatar}`
                  : `${process.env.REACT_APP_COMMON_STATIC}/placeholder.jpg`
              }
              alt={props.history.person.givenNames}
            />
          </Link>

        <div>

          <h4 className="text-sm tracking-tight text-gray-900">
            <Link
              to={`/profile/${props.personId}/histories/${props.history.id}/`}
              className="truncate"
            >
              {props.history.person.surname} {props.history.person.givenNames} 
            </Link>
          </h4>
        </div>
      </div>
      <p className="mb-3 font-light text-gray-500">
        {props.history.previewText?.substring(0, 100) ||
          `${props.history.detailText?.substring(0, 100)} ...`}
      </p>
      <div className="flex justify-between items-center">
        <div className="gap-2">
          <Link
            to={`/tree/${props.personId}/`}
            className="items-center text-primary-600 text-sm"
          >
              <div className="font-medium leading-tight">Автор:</div>
              <span className='underline text-gray-500 leading-tight'>
              {props.history.authorPerson.surname} {props.history.authorPerson.givenNames} 
              </span>
          </Link>
        </div>
        <div className='max-md:hidden'>
          <Link
            to={`/profile/${props.personId}/histories/${props.history.id}/`}
            className="inline-flex items-center font-medium text-primary-600 underline"
          >
            просмотр
            <svg
              className="ml-2 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </Link>
        </div>
      </div>
      </div>
    </article>
  );
}
