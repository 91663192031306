import Router from './Router';
import { FC } from 'react';
import './App.css';

const App: FC = () => {
  return (
    <div className="h-screen flex flex-col">
      <Router />
    </div>
  );
};

export default App;
