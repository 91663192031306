export default function PersonSmallPlaceholder() {
  return (
    <div className=" flex items-center relative person-small">
      <div>
        <div className="hover:scale-125 hover:transition-all">
          <div className="w-14 h-14 object-cover border-2 relative z-10 border-gray-100 rounded-full bg-gray-200 mx-auto"></div>
        </div>
        <span className="w-20 leading-5 block  text-gray-200 text-xs font-medium mr-2 px-2.5 py-0.5 rounded  truncate">
          {'-'}
        </span>
        <span
          className={`w-20 leading-5 block  text-gray-200 text-xs font-medium mr-2 px-2.5 py-0.5 rounded  truncate`}
        >
          {'-'}
        </span>
      </div>
    </div>
  );
}
