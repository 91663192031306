import { Link } from 'react-router-dom';
import Moment from 'moment';

export default function HistoryItem(props: any) {
  return (
    <article
      className="p-6 bg-white rounded-lg border border-gray-200 shadow-emerald-300"
      key={props.history.id}
    >
      <div className="flex justify-between items-center mb-5 text-gray-500">
        <span className="text-sm">
          {Moment(props.history.updatedAt).format('DD.MM.Y')}
        </span>
      </div>
      <h2 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
        <span>{props.history.title || 'Без заголовка'}</span>
      </h2>
      <p className="mb-5 font-light text-gray-500">
        {props.history.previewText}
      </p>
      <div className="flex justify-between items-center">
        <div className="space-x-4">
          <span className="font-medium ">
            Автор:{' '}
            <span className="font-normal">
              {props.history.authorPerson.surname +
                ' ' +
                props.history.authorPerson.givenNames}
            </span>
          </span>
        </div>
        <div>
          {props.accessEdit ? (
            <Link
              to={`/cabinet/person-history/${props.personId}/edit/${props.history.id}/`}
              className="mr-3 inline-flex items-center font-medium text-primary-600  hover:underline"
            >
              редактировать
            </Link>
          ) : (
            ''
          )}
          <Link
            to={`/profile/${props.personId}/histories/${props.history.id}/`}
            className="inline-flex items-center font-medium text-primary-600 hover:underline"
          >
            просмотр
            <svg
              className="ml-2 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </Link>
        </div>
      </div>
    </article>
  );
}
