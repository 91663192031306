import React, { useEffect, useState } from 'react';
import Alert from '../../../common/Alert';
import PersonHistoryService from '../../../../api/PersonHistoryService';
import Autocomplete from '../../../common/autocomplete/AutocompleteInput';
import PersonService from '../../../../api/PersonService';
import { useNavigate, useParams } from 'react-router-dom';
import { IPerson } from '../../../../models/IPerson';

export default function HistoryForm(props: any) {
  const [toYear, setToYear] = useState('');
  const [year, setYear] = useState('');
  const [detailText, setDetailText] = useState('');
  const [previewText, setPreviewText] = useState('');
  const [title, setTitle] = useState('');

  const [person, setPerson] = useState({} as IPerson);

  const [authorPersonId, setAuthorPersonId] = useState(0);
  const [authorPerson, setAuthorPerson] = useState('');
  const [suggestAuthorPerson, setSuggestAuthorPerson] = useState([]);

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const { personId: id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    clearFormState();

    loadPersonInput();

    if (props.editId) {
      loadHistory();
    }
  }, [props.editId]);

  const loadHistory = () => {
    PersonHistoryService.detail(props.editId).then(({ data }) => {
      setHistory(data.history);
    });
  };

  const loadPersonInput = () => {
    PersonService.getPerson(Number(id)).then(({ data }) => {
      setPerson(data);
      setAuthorPerson(`${data.surname} ${data.givenNames}`);
      setAuthorPersonId(data.id);
    });
  };

  const loadAuthorPersonInput = (id: number) => {
    PersonService.getPerson(id).then(({ data }) => {
      setAuthorPerson(`${data.surname} ${data.givenNames}`);
      setAuthorPersonId(data.id);
    });
  };

  const handleAddOrUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    processAddOrUpdate();
  };

  const processAddOrUpdate = async () => {
    setError('');
    setSuccess('');

    const params = {
      previewText,
      detailText,
      title,
      year,
      toYear,
      authorPersonId,
      personId: person.id,
    };

    props.editId ? updateHistory(props.editId, params) : createHistory(params);
  };

  const createHistory = async (params: any) => {
    await PersonHistoryService.create(params).then(({ data }: any) => {
      if (data.errors) {
        showErrors(data.errors);
        return;
      }

      if (data.success) {
        navigate(`/cabinet/person-history/${id}`, { replace: true });
      }
    });
  };

  const updateHistory = async (historyId: string, params: any) => {
    await PersonHistoryService.update({ ...params, id: historyId }).then(
      ({ data }: any) => {
        if (data.errors) {
          showErrors(data.errors);
          return;
        }

        if (data.success) {
          setSuccess('Успешно обновили');
          loadHistory();
        }
      },
    );
  };

  const showErrors = (errors: any) => {
    let msg: any = [];
    errors.forEach((error: any) => {
      msg.push(error[Object.keys(error)[0]]);
    });

    setError(msg.join(', '));
  };

  const clearFormState = () => {
    setPreviewText('');
    setDetailText('');
    setTitle('');
    setAuthorPersonId(0);
    setYear('');
    setToYear('');

    setError('');
    setSuccess('');
  };

  const setHistory = (history: any) => {
    setPreviewText(history.previewText);
    setDetailText(history.detailText);
    setTitle(history.title);
    setYear(history.year);
    setToYear(history.toYear || '');
    setAuthorPersonId(history.authorPersonId);

    loadAuthorPersonInput(history.authorPersonId);
  };

  const handleSearchedAuthorPerson = (query: string) => {
    PersonService.findByQuery(query).then(({ data }) => {
      setSuggestAuthorPerson(data.data);
    });
  };

  const handleSelectAuthorPersonValue = (person: any) => {
    setAuthorPersonId(person.id);
    setSuggestAuthorPerson([]);
    setAuthorPerson(`${person.surname} ${person.givenNames}`);
  };

  return (
    <div>
      <div>
        <h3 className="mb-4 text-xl font-medium text-gray-900 ">
          {props.editId ? 'Редактировать историю' : 'Добавить историю'} для{' '}
          {person.surname} {person.givenNames}
        </h3>

        {error && <Alert text={error} />}
        {success && <Alert text={success} />}

        <form
          className="space-y-6"
          action="#"
          method="POST"
          onSubmit={handleAddOrUpdate}
        >
          <div>
            <label
              htmlFor="parentMale"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Автор
            </label>
            <Autocomplete
              handleKeyup={handleSearchedAuthorPerson}
              handleSelectValue={handleSelectAuthorPersonValue}
              suggestions={suggestAuthorPerson}
              defaultValue={authorPerson}
            />
            <button
              type="button"
              className="text-red-500"
              onClick={() => setAuthorPerson('')}
            >
              очистить
            </button>
          </div>
          <div>
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Заголовок истории *
            </label>
            <input
              type="text"
              name="title"
              id="title"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
              placeholder="Дело было вечером"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </div>
          <div>
            <label
              htmlFor="previewText"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Превью текст *
            </label>
            <textarea
              name="previewText"
              id="previewText"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
              placeholder="Коротко опишите о чем история"
              onChange={(e) => setPreviewText(e.target.value)}
              value={previewText}
            ></textarea>
          </div>
          <div>
            <label
              htmlFor="detailText"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Основной текст 
            </label>
            <textarea
              name="detailText"
              id="detailText"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
              placeholder="Основной текст"
              onChange={(e) => setDetailText(e.target.value)}
              value={detailText}
              rows={20}
            ></textarea>
          </div>
          <div className='relative bg-slate-50 rounded-xl overflow-hidden text-sm p-4'>
            <h3 className='text-gray-400 font-bold'>Подсказки для форматирования текста</h3>
            <div><span className='font-bold'>*текст*</span> - выделить жирным</div>
            <div><span className='italic'>_текст_</span> - сделать курсив</div>
            <div>[[номер картинки]] - вывести в истории картинку (Например: [[1]] будет выведена первая картинка)</div>
          </div>
          <div>
            <label
              htmlFor="year"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Дата события или укажите событие от/до *
            </label>
            <input
              type="date"
              name="year"
              id="year"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
              placeholder="Ivan Petrovich"
              onChange={(e) => setYear(e.target.value)}
              value={year}
            />
          </div>
          <div>
            <label
              htmlFor="toYear"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Дата события, до (необязательно)
            </label>
            <input
              type="date"
              name="toYear"
              id="toYear"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
              placeholder="Ivan Petrovich"
              onChange={(e) => setToYear(e.target.value)}
              value={toYear}
            />
          </div>

          <button
            type="submit"
            className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            {props.editId ? 'Редактировать историю' : 'Добавить историю'}
          </button>
        </form>
      </div>
    </div>
  );
}
