import { FC, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import HistoryForm from '../../../modules/manager-cabinet/components/histories/HistoryForm';
import PersonPhotoForm from '../../../modules/manager-cabinet/components/persons/PersonPhotoForm';
import PersonPhotoList from '../../../modules/manager-cabinet/components/persons/PersonPhotoList';
import PersonPhotoService from '../../../api/PersonPhotoService';
import TooltipText from './TooltipText';
import HistoryPhotoList from '../../../modules/manager-cabinet/components/histories/HistoryPhotoList';
import BaseModal from '../../../modules/common/BaseModal';
import HistoryPhotoService from '../../../api/HistoryPhotoService';

const PersonEdit: FC = () => {
  const { personId, id } = useParams();
  let navigate = useNavigate();
  const [photos, setPhotos] = useState([]);
  const [historyPhotos, setHistoryPhotos] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!personId) {
      navigate('/cabinet/person-history', { replace: true });
    }

    loadPhotos();
  }, [personId]);

  const loadPhotos = () => {
    PersonPhotoService.getList(1, {
      personId: Number(personId),
    }).then(({ data }) => {
      setPhotos(data);
    });
  };

  const loadHistoryPhotos = () => {
    HistoryPhotoService.getList(Number(id)).then(({data}) => {
      setHistoryPhotos(data.data);
    });
  };

  const addToHistory = (photoId: number) => {
    HistoryPhotoService.create({
      personHistoryId: Number(id),
      personPhotoId: Number(photoId),
    }).then(() => {
      setShowModal(false);
      loadHistoryPhotos();
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="container mx-auto p-3 pb-10">
      <Link
        to={`/cabinet/person-history/${personId}`}
        className="font-medium text-blue-600  hover:underline"
      >
        Назад к списку
      </Link>
      <div className="flex gap-10 sm:flex-row flex-col">
        <div className="sm:w-2/4 w-full mt-3">
          <HistoryForm editId={id} />
        </div>
        <div className="sm:w-2/4 w-full mt-3">
          <div className='border border-gray-400 p-5'>
            <HistoryPhotoList
              historyId={id} 
              photos={historyPhotos}
              handleLoadPhotos={loadHistoryPhotos}
              handleOpenModal={() => setShowModal(true)}
            />
          </div>
        </div>
      </div>
      
      <BaseModal
        showModal={showModal} 
        handleCloseModal={closeModal} 
        FormComponent={() => <div className='border border-gray-400 p-5'>
            <PersonPhotoForm
              personId={personId}
              historyId={id}
              handleLoadPhotos={loadPhotos}
            />
            <PersonPhotoList
              photos={photos}
              handleLoadPhotos={loadPhotos}
              handleAddToHistory={addToHistory}
              personId={personId}
              historyId={id}
              inHistory={true}
            /></div>} />
      <TooltipText />
    </div>
  );
};

export default PersonEdit;
