import { FC } from 'react';

const TooltipText: FC = () => {
  return (
  <div className=' bg-slate-50 rounded-xl w-full text-sm p-4 mt-10'>
    <h1 className='text-gray-600 text-lg font-bold leading-5 mb-3'>Рекомендации по заполнению историй</h1>
    <ul className='list-disc pl-5'>
      <li>Добавление фотографий и аудиозаписей возможно только после добавления новой истории;</li>
      <li>Обязательные поля: Заголовок истории и примерная дата истории;</li>
    </ul>
  </div>
  );
};

export default TooltipText;
