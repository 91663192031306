import { useActions } from '../../../../hooks/useActions';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import UserService from '../../../../api/UserService';
import Moment from 'moment';

export default function ManagerList(props: any) {
  const { users } = useTypedSelector((state) => state.users);
  const { loadUsers } = useActions();

  const handleDelete = (email: string) => {
    UserService.deleteUser(email).then(() => {
      loadUsers();
    });
  };

  const listItems = users.map((el: any) => {
    return (
      <tr
        key={el.id}
        className="bg-white border-b"
      >
        <th
          scope="row"
          className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
        >
          <strong>{el.group}</strong>: {el.email}
        </th>
        <td className="py-4 px-6">{Moment(el.createdAt).format('DD.MM.Y')}</td>
        <td className="py-4 px-6  text-right">
          <button
            onClick={() => props.handleEdit(el.id)}
            className="font-medium text-blue-600  hover:underline mr-4"
          >
            Редактировать
          </button>
          <button
            className="font-medium text-red-600 hover:underline"
            onClick={() => handleDelete(el.email)}
          >
            Удалить
          </button>
        </td>
      </tr>
    );
  });

  return (
    <div className="overflow-x-auto relative mt-4">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
          <tr>
            <th scope="col" className="py-3 px-6">
              Email
            </th>
            <th scope="col" className="py-3 px-6">
              Регистрация
            </th>
            <th scope="col" className="py-3 px-6"></th>
          </tr>
        </thead>
        <tbody>{listItems}</tbody>
      </table>
    </div>
  );
}
