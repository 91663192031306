import { useActions } from '../../../../hooks/useActions';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import PersonService from '../../../../api/PersonService';
import { Link } from 'react-router-dom';
import Pagination from '../../../common/Pagination';
import Moment from 'moment';
import ApproximateDate from '../../../common/ApproximateDate';

export default function ListPerson(props: any) {
  const { persons } = useTypedSelector((state) => state.persons);
  const { loadPersons } = useActions();

  const handleDelete = (id: number) => {
    PersonService.deletePerson(id).then(() => {
      loadPersons();
    });
  };

  const handleSetPage = (page: number) => {
    loadPersons(page);
  };

  if (!persons.data) return <></>;

  const listItems = persons.data.map((el: any) => {
    return (
      <tr
        key={el.id}
        className="bg-white border-b "
      >
        <td >
          <Link
            to={`/cabinet/person/edit/${el.id}`}
            className='w-14 h-14 block'
          >
          <img
            className="w-14 h-14 bg-slate-400 rounded-full object-cover"
            src={
              el.avatar
                ? `${process.env.REACT_APP_PERSONS_SMALL_STATIC}${el.avatar}`
                : `${process.env.REACT_APP_COMMON_STATIC}/placeholder.jpg`
            }
            alt={el.givenNames}
          />
          </Link>
        </td>
        <th
          scope="row"
          className="md:py-6 sm:py-8 px-6  text-gray-900 whitespace-nowrap "
        >
          <Link
            to={`/cabinet/person/edit/${el.id}`}
            className="text-blue-600 underline font-bold"
          >
            {el.surname} {el.givenNames}
          </Link>
          <div className='gap-2 flex'>

            <Link
              to={`/cabinet/person-history/${el.id}`}
              className="text-gray-400 underline font-normal"
            >
              Истории: {el.histories.length}
            </Link>
            <Link
              to={`/cabinet/person-photo/${el.id}`}
              className="text-gray-400 underline font-normal"
            >
              Фотографии: {el.photos.length}
            </Link>
          </div>
        </th>
        <td className="md:py-6 sm:py-8 px-6 text-center">
          <ApproximateDate
            date={el.birthAt}
            isApproximate={el.isApproximateBirth}
          />
        </td>
        <td className="md:py-6 sm:py-8 px-6 text-center">
          {Moment(el.updatedAt).format('DD.MM.Y')}
        </td>
        <td className="md:py-6 sm:py-8 px-6 text-right ">
          <div className='flex'>
            <Link
              to={`/tree/${el.id}`}
              className="text-blue-600 hover:underline mr-4 font-bold whitespace-nowrap"
            >
              К дереву
            </Link>
            <Link
              to={`/cabinet/person/edit/${el.id}`}
              className="font-regular text-blue-600  hover:underline mr-4 whitespace-nowrap"
            >
              Редактировать
            </Link>
            <button
              className="font-regular text-red-600  hover:underline whitespace-nowrap"
              onClick={() => handleDelete(el.id)}
            >
              Удалить
            </button>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div className="overflow-x-auto relative mt-4">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="py-3 px-6"></th>
            <th scope="col" className="py-3 px-6">
              Полное имя
            </th>
            <th scope="col" className="py-3 px- text-center">
              Дата рождения
            </th>
            <th scope="col" className="py-3 px-6 text-center">
              Дата редактирования
            </th>
            <th scope="col" className="py-3 px-6"></th>
          </tr>
        </thead>
        <tbody>{listItems}</tbody>
      </table>
      <Pagination {...persons} setPage={handleSetPage} />
    </div>
  );
}
