import axios from 'axios';

const unloginStatuses = [403, 401];

const safeRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL ?? 'http://localhost:5000/',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

safeRequest.interceptors.request.use(
  (config) => {
    if (localStorage.token) {
      config.headers = {
        Authorization: `Bearer ${localStorage.token}`,
        Accept: 'application/json',
      };
    } else {
      localStorage.removeItem('token');
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

safeRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (unloginStatuses.includes(error.response.status)) {
      localStorage.removeItem('username');
      localStorage.removeItem('token');

      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

export default safeRequest;
