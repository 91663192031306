import { AxiosResponse } from 'axios';
import SafeAxios from '../config/safe-axios';
import { IPersonAudioRecord } from '../models/IPersonAudioRecord';

export default class PersonPhotoService {
  static async getList(page = 1, params: any): Promise<AxiosResponse> {
    return SafeAxios.get('/person-audio-record', {
      params: {
        ...params,
        page,
      },
    });
  }

  static async add(formData: FormData): Promise<AxiosResponse> {
    return SafeAxios.post<IPersonAudioRecord>(
      '/person-audio-record/create',
      formData,
    );
  }

  static async delete(id: number): Promise<AxiosResponse> {
    return SafeAxios.get<IPersonAudioRecord>(
      `/person-audio-record/delete/${id}`,
    );
  }

  static async detail(id: number): Promise<AxiosResponse> {
    return SafeAxios.get(`/person-audio-record/detail/${id}`);
  }
}
