import { AuthAction, AuthActionEnum, AuthState } from './types';
import { IUser } from './../../../models/IUser';

const initialState: AuthState = {
  isAuth: localStorage.getItem('token') ? true : false,
  error: '',
  errors: [],
  isLoading: false,
  user: {
    username: localStorage.getItem('username') ?? '',
    userId: localStorage.getItem('userId') ?? '',
    group: localStorage.getItem('group') ?? '',
  } as IUser,
};

export default function authReducer(
  state = initialState,
  action: AuthAction,
): AuthState {
  switch (action.type) {
    case AuthActionEnum.SET_AUTH:
      return { ...state, isAuth: action.payload, isLoading: false };
    case AuthActionEnum.SET_USER:
      return { ...state, user: action.payload };
    case AuthActionEnum.SET_ERROR:
      return { ...state, error: action.payload, isLoading: false };
    case AuthActionEnum.SET_ERRORS:
      return { ...state, errors: action.payload, isLoading: false };
    case AuthActionEnum.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}
