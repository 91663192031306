import { AxiosResponse } from 'axios';
import SafeAxios from '../config/safe-axios';
import { IRegisterUser } from '../models/IRegisterUser';

export default class PersonService {
  static async getList(page = 1, query = ''): Promise<AxiosResponse> {
    return SafeAxios.get('/person', {
      params: {
        page,
        searchableText: query,
      },
    });
  }

  static async getForGuest(params = {}): Promise<AxiosResponse> {
    return SafeAxios.get('/person/guest', {
      params,
    });
  }

  static async getSlimList(): Promise<AxiosResponse> {
    return SafeAxios.get('/person/slim-all');
  }

  static async createPerson(formData: FormData): Promise<AxiosResponse> {
    return SafeAxios.post<IRegisterUser>('/person/create', formData);
  }

  static async createUnknownPerson(
    gender: string,
    personId: number,
  ): Promise<AxiosResponse> {
    return SafeAxios.post<IRegisterUser>('/person/create-unknown', {
      gender,
      personId,
    });
  }

  static async editPerson(formData: FormData): Promise<AxiosResponse> {
    return SafeAxios.post<IRegisterUser>('/person/update', formData);
  }

  static async getPerson(id: number): Promise<AxiosResponse> {
    return SafeAxios.get(`/person/detail/${id}`);
  }

  static async findByQuery(query: string): Promise<AxiosResponse> {
    return SafeAxios.get('/person', {
      params: {
        searchableText: query,
      },
    });
  }

  static async getParents(ids: Array<Number>): Promise<AxiosResponse> {
    return SafeAxios.get('/person/parents', {
      params: {
        ids,
      },
    });
  }

  static async getChildrens(params: any): Promise<AxiosResponse> {
    return SafeAxios.get('/person/childrens', {
      params,
    });
  }

  static async deletePerson(id: number): Promise<AxiosResponse> {
    return SafeAxios.post<IRegisterUser>('/person/delete', {
      id,
    });
  }

  static async getPublicPerson(id: number): Promise<AxiosResponse> {
    return SafeAxios.get(`/person/public-detail/${id}`);
  }

  static async updatePersonAvatar(formData: FormData): Promise<AxiosResponse> {
    return SafeAxios.post<IRegisterUser>('/person/update-avatar', formData);
  }
}
