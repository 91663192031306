import { AxiosResponse } from 'axios';
import SafeAxios from '../config/safe-axios';
import { IRegisterGuest } from '../models/IRegisterGuest';

export default class GuestService {
  static async getList(): Promise<AxiosResponse> {
    return SafeAxios.get('/guest');
  }

  static async createGuest<IRegisterGuest>(
    email: string,
    expireDay: number,
  ): Promise<AxiosResponse> {
    return SafeAxios.post<IRegisterGuest>('/guest/create', {
      email,
      expireDay,
    });
  }

  static async deleteGuest(id: number): Promise<AxiosResponse> {
    return SafeAxios.post<IRegisterGuest>('/guest/delete', {
      id,
    });
  }
}
