import { AxiosResponse } from 'axios';
import SafeAxios from '../config/safe-axios';
import { IRegisterUser } from '../models/IRegisterUser';

export default class UserService {
  static async getList(): Promise<AxiosResponse> {
    return SafeAxios.get('/user');
  }

  static async createUser(
    username: string,
    password: string,
    group: string,
  ): Promise<AxiosResponse> {
    return SafeAxios.post<IRegisterUser>('/user/create', {
      email: username,
      password,
      group,
    });
  }

  static async editUser(params: any): Promise<AxiosResponse> {
    return SafeAxios.post<IRegisterUser>('/user/update', params);
  }

  static async getUser(id: number): Promise<AxiosResponse> {
    return SafeAxios.get(`/user/detail/${id}`);
  }

  static async deleteUser(username: string): Promise<AxiosResponse> {
    return SafeAxios.post<IRegisterUser>('/user/delete', {
      email: username,
    });
  }
}
