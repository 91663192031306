import React, { useState } from 'react';

type EntityIdType  = string | number;

interface Props {
  value: string;
  id:  EntityIdType;
  onUpdate: (id:  EntityIdType, newValue: string) => void;
}

const EditableInput: React.FC<Props> = ({ value, id, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  const onClick = () => {
    setIsEditing(true);
  };

  const onBlur = () => {
    setIsEditing(false);
    onUpdate(id, localValue);
  };

  return (
    <div onClick={onClick}>
      {isEditing ? (
        <input
          value={localValue}
          onChange={(e) => setLocalValue(e.target.value)}
          onBlur={onBlur}
        />
      ) : (
        <span>{value}</span>
      )}
    </div>
  );
};

export default EditableInput;
