import Pagination from '../../../common/Pagination';
import PersonHistoryService from '../../../../api/PersonHistoryService';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HistoryItem from './HistoryItem';

export interface PersonDetailProps {
  personId: number;
}

export default function HistoryList() {
  const [histories, setHistories] = useState([] as any);
  const { personId } = useParams();

  const handleSetPage = (page: number) => {
    loadHistory(page);
  };

  useEffect(() => {
    loadHistory();
  }, []);

  const loadHistory = (page = 1) => {
    PersonHistoryService.getList(page, { personId }).then(({ data }) => {
      setHistories(data);
    });
  };

  if (!histories.data) return <></>;

  const listItems = histories.data.map((el: any) => {
    return <HistoryItem history={el} accessEdit={true} personId={personId} key={el.id} />;
  });

  return (
    <div className="overflow-x-auto relative mt-4">
      <div className="grid gap-4 lg:grid-cols-2 mb-8"> {listItems}</div>
      <Pagination {...histories} setPage={handleSetPage} />
    </div>
  );
}
