import { useState, useEffect, FC } from 'react';
import useDebounce from '../../../hooks/useDebounce';

type PersonSuggestType = {
  id: number;
  surname: string;
  givenNames: string;
};

interface AutocompleteProps {
  handleKeyup: Function;
  handleSelectValue: Function;
  suggestions: PersonSuggestType[];
  defaultValue?: string;
}

const AutocompleteInput: FC<AutocompleteProps> = ({
  handleKeyup,
  handleSelectValue,
  suggestions,
  defaultValue = '',
}) => {
  const [query, setQuery] = useState('');
  const [queryValue, setQueryValue] = useState('');
  const [isSelected, setIsSelected] = useState(false);

  const debouncedSearchQuery = useDebounce(query, 500);

  const selectValue = (person: PersonSuggestType) => {
    setQueryValue(`${person.surname} ${person.givenNames}`);
    handleSelectValue(person);
    setIsSelected(true);
  };

  const changeQueryValue = (value: string) => {
    setQuery(value);
    setQueryValue(value);
  };

  useEffect(() => {
    if (debouncedSearchQuery) {
      handleKeyup(debouncedSearchQuery);
    }
  }, [debouncedSearchQuery]);

  const suggestionsList = suggestions.map((person: PersonSuggestType) => {
    return (
      <div
        className="relative cursor-default select-none py-2 pl-10 pr-4 text-gray-900 hover:bg-cyan-600 "
        onClick={() => selectValue(person)}
        key={person.id}
      >
        {person.surname} {person.givenNames}
      </div>
    );
  });

  const suggestionsWrap = (
    <div className="w-full relative">
      <div className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
        {suggestionsList}
      </div>
    </div>
  );

  return (
    <div>
      <input
        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  ${
          isSelected ? 'border-green-500' : ''
        }`}
        type="text"
        onChange={(event) => changeQueryValue(event.target.value)}
        value={queryValue || defaultValue}
        disabled={!!defaultValue}
      />
      {suggestions.length ? suggestionsWrap : ''}
    </div>
  );
};

export default AutocompleteInput;
